import { useEffect, useState } from 'react'
import { Client } from '@stomp/stompjs'
import { notification } from 'antd'
import { useDispatch } from 'react-redux'

import { api } from '../api/api'
import { transactionsCreatePageStatusesActions } from '../store/pages/transactions-create-page/transactions-create-page-statuses/transactions-create-page-statuses.slice'
import {
  ReceiptData,
  TransactionStatusAction,
  TransactionStatusItem,
} from '../store/pages/transactions-create-page/transactions-create-page-statuses/transactions-create-page-statuses.types'
import { WS_V1_URL } from '../app/app.config'

export const useTransactionsStatuses = () => {
  const dispatch = useDispatch()

  const [currTicket, setCurrTicket] = useState('')

  const getTicket = async () => {
    const { data: ticket } = await api.transactionsService.fetchTicket()

    setCurrTicket(ticket)
  }

  useEffect(() => {
    if (currTicket) {
      const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID') as string

      const client = new Client({
        webSocketFactory: () => new WebSocket(`${WS_V1_URL}?ticket=${currTicket}`),
        onWebSocketClose: e => {
          console.log('ConnectionCloded = ', e)
        },
        reconnectDelay: 100000,
        onConnect: () => {
          client.subscribe(`/messages/studios/${studioId}/transactions`, message => {
            const body = JSON.parse(message.body) as TransactionStatusItem
            console.log('Subscribe = ', body)
            dispatch(transactionsCreatePageStatusesActions.updateTransactionsStatus(body))

            if (body.action === TransactionStatusAction.ERROR) {
              notification.error({
                message: body.errorMessage,
              })
            }
          })
          client.subscribe(`/messages/studios/${studioId}/receipts`, message => {
            const body = JSON.parse(message.body) as ReceiptData
            console.log('ReceiptsSubscribe = ', body)

            dispatch(transactionsCreatePageStatusesActions.updateReceiptsState(body))

            if (body.action === TransactionStatusAction.ERROR) {
              notification.error({
                message: body.errorMessage,
              })
            }
          })
        },
        debug: message => console.log('message = ', message),
      })

      client.activate()
    }
  }, [currTicket])

  useEffect(() => {
    getTicket()
  }, [])
}
