import { Button, Card, Divider, Flex, Modal, Progress, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import { ScheduleModalProgressProps } from './schedule-modal-progress.types'
import './schedule-modal-progress.styles.less'

export const ScheduleModalProgress: React.FC<ScheduleModalProgressProps> = props => {
  const { title, messages, totalExercises, onCancel, onConflictResolution } = props

  const progress = useMemo(() => {
    return totalExercises ? Math.round((messages.length * 100) / totalExercises) : 0
  }, [messages.length, totalExercises])

  return (
    <Modal
      className="schedule-modal-progress"
      title={title}
      visible={true}
      width={560}
      onCancel={onCancel}
      footer={
        messages.some(message => message.conflicts !== null || message.error !== null) ? (
          <Flex justify="center">
            <Button loading={progress !== 100} type="primary" onClick={onConflictResolution}>
              Решение конфликтов
            </Button>
          </Flex>
        ) : null
      }
    >
      <Flex vertical gap={16}>
        <Divider className="schedule-modal-progress__divider" />
        {totalExercises && <Progress className="schedule-modal-progress__progress" type="circle" percent={progress} />}
        <Flex vertical gap="5px">
          {messages.map(message => {
            const { exercise } = message

            const startDate = dayjs(exercise.timeFrom)
            const endDate = dayjs(exercise.timeTo)
            const trainerNames = exercise.trainers.map(trainer => trainer.name).join(', ')
            const success = message.error === null && message.conflicts === null && message.action !== 'CANCEL'

            return (
              <Card className="schedule-modal-progress__card">
                <Flex justify="space-between" align="center">
                  <div>
                    <Typography.Title style={{ margin: 0 }} level={5}>
                      {startDate.format('D MMMM')}, с {startDate.format('HH:mm')} до {endDate.format('HH:mm')}
                    </Typography.Title>
                    <Typography.Text>{trainerNames}</Typography.Text>
                  </div>
                  <Tag color={success ? 'green' : 'red'}>
                    {success ? 'Успех' : message.action === 'CANCEL' ? 'Отменена' : 'Конфликт'}
                  </Tag>
                </Flex>
              </Card>
            )
          })}
        </Flex>
      </Flex>
    </Modal>
  )
}
