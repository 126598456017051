import { put, take, takeLatest } from 'redux-saga/effects'

import { apiV2 } from '../../../../api/api-v2'
import { genScheduleExercisesAddDTO } from '../../../../mapping/exercises-timetable.mapping'
import { AppModal } from '../../../../types/modal.types'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { scheduleLongtermPageModalAddActions } from './schedule-longterm-page-modal-add.slice'

function* addExercises(action: ReturnType<typeof scheduleLongtermPageModalAddActions.addExercises>) {
  const { studioId, scheduleId, scheduleFormValues } = action.payload
  const exercisesDTO = genScheduleExercisesAddDTO(scheduleFormValues)

  console.log('exercisesDTO', exercisesDTO)
  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(apiV2.exercisesTimetableService.addExercisesByTimetableId, scheduleId, exercisesDTO)
    yield put(scheduleLongtermPageModalAddActions.addExercisesSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: 'EDIT',
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPageModalAddActions.addExercisesError(new Error()))
  }
}

export function* scheduleLongtermPageModalAddSagas() {
  yield takeLatest(scheduleLongtermPageModalAddActions.addExercises, addExercises)
}
