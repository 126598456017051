import { CloseOutlined, LinkOutlined } from '@ant-design/icons'
import { Button, Flex, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { clsx } from 'clsx'
import CopyToClipboard from 'react-copy-to-clipboard'

import './side-peek.styles.less'
import { peekActions } from '../../store/common/peek/peek.slice'
import { getTheme } from '../../store/common/layout/layout.selectors'

interface Props {
  children: React.ReactNode
  link?: string
  onCancel?: () => void
}

export const SidePeek: React.FC<Props> = props => {
  const { children, link, onCancel } = props

  const dispatch = useDispatch()

  const theme = useSelector(getTheme)

  const onCancelHandler = useCallback((): void => {
    dispatch(peekActions.closeLast())
    if (onCancel) {
      onCancel()
    }
  }, [dispatch, onCancel])

  return (
    <Flex
      justify="end"
      className={clsx(`side-peek ${theme !== 'light' && 'side-peek--dark'}`)}
      onClick={e => e.currentTarget === e.target && onCancelHandler()}
    >
      <Flex className="side-peek__wrapper" vertical gap="middle">
        <Flex gap="middle">
          <Button icon={<CloseOutlined />} onClick={onCancelHandler} />
          {link && (
            <CopyToClipboard text={link}>
              <Button
                onClick={() => {
                  message.success({
                    content: 'Ссылка успешно скопирована',
                    className: 'side-peek__message',
                  })
                }}
                icon={<LinkOutlined />}
              />
            </CopyToClipboard>
          )}
        </Flex>
        <Flex className="side-peek__content" vertical>
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}
