import { Col, Flex, Row, Select, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { FC } from 'react'
import {
  CalendarOutlined,
  InboxOutlined,
  InsertRowAboveOutlined,
  SolutionOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { formatPhoneNumber } from '../../../format/phone.format'
import { formatPaymentType, formatPluralized } from '../../../format/text.format'
import { ScheduleOverviewProps } from './schedule-overview.types'

import './schedule-overview.styles.less'

export const ScheduleOverview: FC<ScheduleOverviewProps> = props => {
  const { schedule } = props

  return (
    <div>
      <Typography.Title level={4} className="schedule-overview__title">
        Длинная запись клиента {formatPhoneNumber(schedule.phone)}{' '}
      </Typography.Title>
      <Flex vertical gap={16}>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <InsertRowAboveOutlined className="schedule-overview__icon" />
              Количество
            </Typography.Text>
          </Col>
          <Col>{formatPluralized(schedule.totalExercises, ['запись', 'записи', 'записей'])}</Col>
        </Row>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="schedule-overview__icon" />
              Период
            </Typography.Text>
          </Col>
          <Col>
            С {dayjs(schedule.dateFrom).format('DD MMMM YYYY')} по {dayjs(schedule.dateTo).format('DD MMMM YYYY')}
          </Col>
        </Row>
        {schedule.direction && (
          <Row align="middle">
            <Col style={{ width: '250px' }}>
              <Typography.Text type="secondary">
                <InboxOutlined className="schedule-overview__icon" />
                Услуга
              </Typography.Text>
            </Col>
            <Col>
              <Tag color="magenta">{schedule.direction?.name}</Tag>
            </Col>
          </Row>
        )}
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <SolutionOutlined className="schedule-overview__icon" />
              Статус оплаты
            </Typography.Text>
          </Col>
          <Col>{formatPaymentType(schedule.paymentType)}</Col>
        </Row>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <StarOutlined className="schedule-overview__icon" />
              Клиент
            </Typography.Text>
          </Col>
          <Col>
            <Typography style={{ color: '#1677ff', cursor: 'pointer' }}>{formatPhoneNumber(schedule.phone)}</Typography>
          </Col>
        </Row>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <UserOutlined className="schedule-overview__icon" />
              Менеджер
            </Typography.Text>
          </Col>
          <Col>
            <Select style={{ width: '177px' }} placeholder="Менеджер" disabled />
          </Col>
        </Row>
      </Flex>
    </div>
  )
}
