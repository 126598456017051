import { Button, DatePicker, Tabs, Typography } from 'antd'
import { useState } from 'react'
import { Dayjs } from 'dayjs'

import styles from './reports-page.module.scss'
import { ReportItem } from './report-item.components'

export const ReportsPage = () => {
  const [currentDates, setCurrentDates] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [appliedDates, setAppliedDates] = useState<[Dayjs | null, Dayjs | null]>([null, null])

  console.log('currentDates = ', currentDates)

  const isButtonDisabled = !currentDates.every(Boolean)

  return (
    <div>
      <div className={styles.tabsContainer}>
        <Tabs
          className={styles.tabs}
          size="middle"
          items={[
            {
              label: 'Руководителю',
              key: 'supervisor',
            },
            {
              label: 'Финансисту',
              key: 'finance',
            },
            {
              label: 'Продакту',
              key: 'product',
            },
            {
              label: 'Бухгалтеру',
              key: 'accountant',
            },
            {
              label: 'Менеджеру',
              key: 'manager',
            },
          ]}
        />
      </div>
      <div className={styles.filtersContainer}>
        <DatePicker.RangePicker
          className={styles.rangePicker}
          value={currentDates}
          onChange={setCurrentDates}
          placeholder={['Дата начала', 'Дата окончания']}
        />
        <Button
          disabled={isButtonDisabled}
          type="primary"
          onClick={() => {
            setAppliedDates(currentDates)
          }}
        >
          Применить
        </Button>
      </div>
      <div className={styles.content}>
        <div>
          <ReportItem generalDates={appliedDates} title="Зарплаты тренеров" url="trainers/salary" />
          <ReportItem generalDates={appliedDates} title="Сводный отчёт" url="general" />
        </div>
        <div>
          <ReportItem generalDates={appliedDates} title="Отчёт по клиентам" url="clients" />
        </div>
        <div>
          <ReportItem generalDates={appliedDates} title="Отчёт о выручке по исполнителям" url="employee/income" />
        </div>
      </div>
    </div>
  )
}
