import { Store } from 'antd/es/form/interface'

export function genScheduleSlotsInitialValues(): Store[] {
  return [
    {
      time: [null, null],
      room: null,
      totalCapacity: null,
      maxClientsCount: 1,
      trainers: [],
    },
  ]
}
