import { Client } from '@stomp/stompjs'
import { eventChannel } from 'redux-saga'

import { api } from '../../../api/api'
import { WS_V1_URL } from '../../../app/app.config'

export const getWebSocketTicket = async (): Promise<string> => {
  const { data: ticket } = await api.authorizationTicketsService.fetchTicket()
  return ticket
}

export const createWebSocketChannel = (ticket: string, destination: string) => {
  let client: Client | null = null
  const channel = eventChannel(emitter => {
    client = new Client({
      webSocketFactory: () => new WebSocket(`${WS_V1_URL}?ticket=${ticket}`),
      reconnectDelay: 0,
      onWebSocketClose: e => {
        emitter({ type: 'DISCONNECT' })
      },
      onConnect: () => {
        client?.subscribe(destination, message => {
          const body = JSON.parse(message.body)
          emitter(body)
        })
        emitter({ type: 'CONNECT' })
      },
      onWebSocketError: error => {
        emitter({ type: 'ERROR', error })
      },
      debug: message => console.log('WebSocket message:', message),
    })

    client.activate()

    return () => {
      if (client) {
        client.deactivate()
      }
    }
  })

  return { channel, client }
}
