import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { ScheduleLongtermPageParams } from '../../../../pages/schedule-longterm-page/schedule-longterm-page.types'
import { Nullable } from '../../../../types/lang.types'
import { ScheduleLongtermPageListFetchPageDataSuccessPayload } from './schedule-longterm-page-list.types'

export interface ScheduleLongtermPageListState {
  exercisesTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable[]>
  studiosRooms: Nullable<StudiosRoomsApi.StudioRoom[]>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPageListState = {
  exercisesTimetable: null,
  studiosRooms: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: scheduleLongtermPageListActions, reducer: scheduleLongtermPageListReducer } = createSlice({
  name: '@@schedule-longterm-page-list',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<ScheduleLongtermPageListState>, _: PayloadAction<ScheduleLongtermPageParams>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<ScheduleLongtermPageListState>,
      action: PayloadAction<ScheduleLongtermPageListFetchPageDataSuccessPayload>
    ) => {
      state.exercisesTimetable = action.payload.exercisesTimetable
      state.studiosRooms = action.payload.studiosRooms
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<ScheduleLongtermPageListState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    cancelSchedule: (state: Draft<ScheduleLongtermPageListState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    cancelScheduleSuccess: (state: Draft<ScheduleLongtermPageListState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    cancelScheduleError: (state: Draft<ScheduleLongtermPageListState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    reset: () => initialState,
  },
})
