import { SelectRecordType } from '@components/ui/select-record-type/ui/select-record-type.component'
import { Col, Flex, Form, Input, Select, Switch, Typography } from 'antd'

import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { ScheduleSlots } from '../schedule-slots/schedule-slots.component'
import { useScheduleForm } from './schedule-form.hook'
import { ScheduleFormProps } from './schedule-form.types'
const { TextArea } = Input

export const ScheduleForm = (props: ScheduleFormProps) => {
  const { form, loading, type } = props
  const { directions, studiosRoomsOptions } = props
  const { longterm } = props
  const { onFieldsChange } = props

  const {
    direction,
    initialValues,
    directionValidationRules,
    typeValidationRules,
    phoneValidationRules,
    onFieldsChangeHandler,
    onChangeDirectionHandler,
    onChangePaymentTypeHandler,
    toggleShowComment,
    toggleShowClientRecord,
    toggleHasFocusGuestsSearch,
    isShowComment,
    isShowClientRecord,
    paymentMethodOptions,
    disabledClients,
    isDirectionDisabled,
    selectedTypeExercise,
    onChangeExercisesTypeHandler,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
    exercisesOptions,
    directionsOptions,
    masterServicesOptions,
    subServicesOptions,
    disabledType,
    trainersOptions,
    typeForm,
  } = useScheduleForm({ directions, form, onFieldsChange, type })

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      disabled={loading}
      initialValues={initialValues}
      onFieldsChange={onFieldsChangeHandler}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item label="Категория записей" name="type" rules={typeValidationRules}>
        <SelectRecordType
          placeholder="Категория записей"
          showIcon={true}
          loading={loading}
          disabled={disabledType}
          options={exercisesOptions}
          value={form.getFieldValue('type')}
          onChangeSelect={onChangeExercisesTypeHandler}
        />
      </Form.Item>

      {!isDirectionDisabled &&
        (selectedTypeExercise === 'PERSONAL' ? (
          <>
            <Col style={{ marginTop: 15 }}>
              <Form.Item label="Персональные услуги" name="masterService" rules={directionValidationRules}>
                <SelectRecordType
                  placeholder="Персональные услуги"
                  loading={loading}
                  disabled={loading}
                  showIcon={false}
                  options={masterServicesOptions}
                  onChangeSelect={onChangeMasterServiceHandler}
                  value={form.getFieldValue('masterService')}
                />
              </Form.Item>
            </Col>
            <Col style={{ marginTop: 15 }}>
              <Form.Item label="Под-услуга" name="subService" rules={directionValidationRules}>
                <SelectRecordType
                  placeholder="Под-услуга"
                  loading={loading}
                  disabled={loading}
                  showIcon={false}
                  options={subServicesOptions}
                  onChangeSelect={onChangeSubServicesHandler}
                  value={form.getFieldValue('subService')}
                />
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col style={{ marginTop: 15 }}>
            <Form.Item label="Групповые направления" name="direction" rules={directionValidationRules}>
              <Select
                placeholder="Групповые направления"
                options={directionsOptions}
                disabled={loading}
                loading={loading}
                onChange={onChangeDirectionHandler}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                dropdownClassName="customDropdown"
              />
            </Form.Item>
          </Col>
        ))}

      <div style={{ paddingTop: 20 }} className="exercises-form__section exercises-form__section--without-bg">
        <Flex gap="small" align="center">
          <Switch
            disabled={!typeForm}
            size="small"
            defaultValue={isShowComment}
            value={isShowComment}
            onChange={toggleShowComment}
          />
          <Typography.Text
            disabled={!typeForm}
            className="exercises-form__section-label"
            onClick={() => {
              if (!!typeForm) {
                toggleShowComment()
              }
            }}
          >
            Комментарий ко всем созданным записям
          </Typography.Text>
        </Flex>
      </div>

      <div style={{ display: isShowComment ? 'block' : 'none', marginBottom: 15 }}>
        <Form.Item name="comment">
          <TextArea value={form.getFieldValue('comment')} />
        </Form.Item>
      </div>
      {!disabledClients && (
        <div className="exercises-form__section exercises-form__section--without-bg">
          <Flex gap="small" align="center">
            <Form.Item name="bookClient" valuePropName="bookClient">
              <Switch
                disabled={type === 'edit' || !typeForm}
                size="small"
                value={isShowClientRecord}
                onChange={toggleShowClientRecord}
              />
            </Form.Item>

            <Typography.Text
              className="exercises-form__section-label"
              style={{ pointerEvents: type === 'edit' ? 'none' : 'auto' }}
              disabled={type !== 'edit' && !typeForm}
              onClick={() => {
                if (type !== 'edit' && !!typeForm) {
                  toggleShowClientRecord()
                }
              }}
            >
              Запись клиента сразу
            </Typography.Text>
          </Flex>
        </div>
      )}

      <div className="exercises-form__section" style={{ display: isShowClientRecord ? 'block' : 'none' }}>
        <Typography.Title level={5}>Выберите клиента</Typography.Title>
        <div style={{ marginTop: 15 }}>
          <Form.Item
            rules={isShowClientRecord ? phoneValidationRules : undefined}
            validateTrigger="onBlur"
            label={<span onClick={toggleHasFocusGuestsSearch}>Клиент</span>}
            name="phone"
          >
            <ClientsAutocompleteContainer
              defaultValue={form.getFieldValue('phone')}
              name="phone"
              form={form}
              disabled={loading}
            />
          </Form.Item>
        </div>
        <div style={{ marginTop: 15 }}>
          <Form.Item
            rules={
              isShowClientRecord
                ? [
                    {
                      required: isShowClientRecord,
                      message: 'Пожалуйста, выберите метод оплаты',
                    },
                  ]
                : undefined
            }
            label="Метод оплаты"
            name="paymentType"
          >
            <Select placeholder="Выбрать" options={paymentMethodOptions} onChange={onChangePaymentTypeHandler} />
          </Form.Item>
        </div>
      </div>

      <ScheduleSlots
        isDisabled={!typeForm}
        isLoading={loading}
        form={form}
        trainersOptions={trainersOptions}
        studiosRoomsOptions={studiosRoomsOptions}
      />
    </Form>
  )
}
