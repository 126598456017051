import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { ScheduleList } from '../../../components/schedule/schedule-list/schedule-list.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleLongtermPageTable } from './schedule-longterm-page-table.hook'

export const ScheduleLongtermPageTable = () => {
  const { schedule, onEditHandler, onRemoveHandler } = useScheduleLongtermPageTable()

  if (isDefAndNotEmpty(schedule)) {
    return <ScheduleList schedule={schedule} onEdit={onEditHandler} onRemove={onRemoveHandler} />
  }
  return <PageEmpty description="Нет расписания" />
}
