import { MinusOutlined } from '@ant-design/icons'
import { TimeRangePicker } from '@components/ui/time-range-picker/time-range-picker.component'
import { ITimeRangePickerValue } from '@components/ui/time-range-picker/time-range-picker.types'
import { Button, Form, FormInstance, InputNumber, Select, Space } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { genExercisesFormMaxClientsCountValidationRules } from '@components/exercises/exercises-form/exercises-form.utils'

import { ExercisesDirectionsApi } from '../../../../api/types/exercises-directions-api.types'
import { Days } from '../../../../types/days.types'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import './schedule-slots-item.styles.less'
import {
  genScheduleSlotsItemMaxClientCountValidationRules,
  genScheduleSlotsItemRoomValidationRules,
  genScheduleSlotsItemTimeValidationRules,
} from './schedule-slots-item.utils'
import { ScheduleFormValues } from '../../schedule-form/schedule-form.types'
import { ScheduleSlot } from '../schedule-slots.types'

interface Props {
  form: FormInstance<ScheduleFormValues>
  day: Days
  loading: boolean
  name: number
  studiosRoomsOptions?: DefaultOptionType[]
  trainersOptions?: DefaultOptionType[]
  onRemove: (index: number) => void
  direction?: ExercisesDirectionsApi.ExerciseDirection
  disabled: boolean
}

export const ScheduleSlotsItem = (props: Props) => {
  const { form, name, day, loading, studiosRoomsOptions, trainersOptions, disabled } = props
  const { onRemove } = props

  const [startTime, setStartTime] = useState<string | null>()
  const [endTime, setEndTime] = useState<string | null>()

  const timeRangeWatch = Form.useWatch(['slots', day, name, 'time'])
  const timeSlots = Form.useWatch(['slots', day])

  const onRemoveHandler = useCallback((): void => {
    onRemove(name)
  }, [name, onRemove])

  const onChangeTimeHandler = (value: ITimeRangePickerValue): void => {
    const values = form.getFieldsValue()
    const slotsByDay = values.slots[day]

    if (isDefAndNotEmpty(slotsByDay)) {
      if (value?.start) setStartTime(value.start)
      if (value?.end) setEndTime(value.end)
      const time = [value?.start ?? startTime, value?.end ?? endTime]

      const daysSlots = slotsByDay.map((slot: ScheduleSlot, index: number): ScheduleSlot => {
        return index === name ? { ...slot, time } : slot
      })
      form.setFieldsValue({
        ...values,
        slots: {
          ...values.slots,
          [day]: daysSlots,
        },
      })
    }
  }

  useEffect(() => {
    setStartTime(timeRangeWatch?.[0])
    setEndTime(timeRangeWatch?.[1])
  }, [timeRangeWatch])

  const timeRangeForTimePicker = useMemo(() => {
    return { start: timeRangeWatch?.[0], end: timeRangeWatch?.[1] }
  }, [timeRangeWatch])

  const timeValidationRules = useMemo(
    () =>
      genScheduleSlotsItemTimeValidationRules(
        timeRangeForTimePicker.start,
        timeRangeForTimePicker.end,
        timeSlots,
        name
      ),
    [timeRangeForTimePicker, day]
  )
  const roomValidationRules = genScheduleSlotsItemRoomValidationRules()
  const maxClientCountsValidationRules = [
    ...genScheduleSlotsItemMaxClientCountValidationRules(),
    ...genExercisesFormMaxClientsCountValidationRules(),
  ]

  return (
    <Space className="schedule-slots-item" size="small">
      <Form.Item className="schedule-slots-item__form-item" name={[name, 'time']} rules={timeValidationRules}>
        <TimeRangePicker times={timeRangeForTimePicker} disabled={disabled} onChange={onChangeTimeHandler} />
      </Form.Item>

      <Form.Item className="schedule-slots-item__form-item" name={[name, 'room']}>
        <Select placeholder="Зал" options={studiosRoomsOptions} disabled={disabled} loading={loading} />
      </Form.Item>

      <Form.Item className="schedule-slots-item__form-item" name={[name, 'maxClientsCount']} initialValue={1}>
        <InputNumber disabled={disabled} placeholder="Вместимость" min={1} />
      </Form.Item>

      <Form.Item className="schedule-slots-item__form-item" name={[name, 'trainers']}>
        <Select
          disabled={disabled}
          placeholder="Исполнители"
          options={trainersOptions}
          loading={loading}
          mode="multiple"
          showSearch={true}
          filterOption={(input, option) => {
            const label = option?.label ? String(option.label) : ''
            return label.toLowerCase().includes(input.toLowerCase())
          }}
          dropdownClassName="customDropdown"
        />
      </Form.Item>
      <Form.Item className="schedule-slots-item__form-item">
        <Button disabled={disabled} icon={<MinusOutlined />} size="middle" danger onClick={onRemoveHandler} />
      </Form.Item>
    </Space>
  )
}
