import { Form } from 'antd'
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import {
  getScheduleLongtermPageModalEditIsLoading,
  getScheduleLongtermPageModalEditFormValues,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-edit/schedule-longterm-page-modal-edit.selectors'
import { scheduleLongtermPageModalEditActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-edit/schedule-longterm-page-modal-edit.slice'
import {
  genScheduleLongtermPagePeekAboutExercisesTotalElement,
  genScheduleLongtermPagePeekAboutStudiosOptions,
  genScheduleLongtermPagePeekAboutStudiosRoomsOptions,
  genScheduleLongtermPagePeekAboutTrainersOptions,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'

export function useScheduleLongtermPageModalEdit(studioId: string, scheduleId: string) {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const studiosOptions = useSelector(genScheduleLongtermPagePeekAboutStudiosOptions)
  const studiosRoomsOptions = useSelector(genScheduleLongtermPagePeekAboutStudiosRoomsOptions)
  const trainersOptions = useSelector(genScheduleLongtermPagePeekAboutTrainersOptions)
  const isLoading = useSelector(getScheduleLongtermPageModalEditIsLoading)
  const formValues = useSelector(getScheduleLongtermPageModalEditFormValues)

  const totalExercises = useSelector(genScheduleLongtermPagePeekAboutExercisesTotalElement)

  const onEditExercisesHandler = useCallback((): void => {
    const scheduleFormValues = form.getFieldsValue()
    dispatch(scheduleLongtermPageModalEditActions.updateExercises({ studioId, scheduleId, scheduleFormValues }))
  }, [dispatch, form, scheduleId, studioId])

  const onRemoveExercisesHandler = useCallback((): void => {
    dispatch(scheduleLongtermPageModalEditActions.removeExercises({ studioId, scheduleId }))
  }, [dispatch, scheduleId, studioId])

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [dispatch, form, formValues])

  useEffect(() => {
    return () => {
      dispatch(scheduleLongtermPageModalEditActions.reset())
    }
  }, [dispatch])

  return {
    form,
    formValues,
    isLoading,
    totalExercises,
    studiosOptions,
    studiosRoomsOptions,
    trainersOptions,
    onEditExercisesHandler,
    onRemoveExercisesHandler,
    onCancelHandler,
  }
}
