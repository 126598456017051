import { Select } from 'antd'
import { CaretDownFilled, CaretUpFilled, InfoCircleOutlined } from '@ant-design/icons'

import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { formatRubleCurrency } from '../../../format/number.format'
import styles from './progress-analytic.module.scss'

type Props = {
  title: string
  value: number
  max: number
  last: number
  yesterday: number
  isLoading: boolean
  noCurrency?: boolean
}

export const ProgressAnalytic = ({ title, value, max, last, yesterday, isLoading, noCurrency }: Props) => {
  const percent = (value / max) * 100
  const resultPercent = percent > 100 ? 100 : percent
  return (
    <div className={styles.container}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div className={styles.title}>
            {title} <InfoCircleOutlined />
          </div>
          <div className={styles.price}>{!noCurrency ? formatRubleCurrency(value) : value}</div>
          <div>
            <Select
              className={styles.select}
              value="one"
              options={[
                {
                  value: 'one',
                  label: 'Услуги',
                },
              ]}
            />
          </div>
          <div className={styles.progressContainer}>
            <div className={styles.progressBase}>
              <div
                style={{
                  width: `${resultPercent}%`,
                }}
                className={styles.progressLine}
              ></div>
            </div>
            <div className={styles.progressFooter}>{!noCurrency ? formatRubleCurrency(max) : max}</div>
          </div>
          <div className={styles.footer}>
            <span>
              Прошлый месяц {last}% <CaretUpFilled className={styles.up} />
            </span>
            <span className={styles.down}>
              Вчера {yesterday}% <CaretDownFilled />
            </span>
          </div>
        </>
      )}
    </div>
  )
}
