import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { ScheduleLongtermPageModalConflictsEditExercisePayload } from './schedule-longterm-page-modal-conflicts.types'

export interface ScheduleLongtermPageModalConflictsState {
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPageModalConflictsState = {
  isLoading: false,
  error: null,
}

export const {
  actions: scheduleLongtermPageModalConflictsActions,
  reducer: scheduleLongtermPageModalConflictsReducer,
} = createSlice({
  name: '@@schedule-longterm-page-modal-conflicts',
  initialState,
  reducers: {
    editExercise: (
      state: Draft<ScheduleLongtermPageModalConflictsState>,
      _: PayloadAction<ScheduleLongtermPageModalConflictsEditExercisePayload>
    ) => {
      state.isLoading = true
    },
    editExerciseSuccess: (state: Draft<ScheduleLongtermPageModalConflictsState>) => {
      state.isLoading = false
    },
    editExerciseError: (state: Draft<ScheduleLongtermPageModalConflictsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
