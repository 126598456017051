import { put, select, take, takeLatest } from 'redux-saga/effects'

import { apiV2 } from '../../../../api/api-v2'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { genScheduleExercisesEditDTO } from '../../../../mapping/exercises-timetable.mapping'
import { AppModal } from '../../../../types/modal.types'
import { callApi } from '../../../../utils/sagas.utils'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import { modalActions } from '../../../common/modal/modal.slice'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { genScheduleLongtermPagePeekAboutExercisesFilter } from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPageModalEditActions } from './schedule-longterm-page-modal-edit.slice'

function* updateExercises(action: ReturnType<typeof scheduleLongtermPageModalEditActions.updateExercises>) {
  const { studioId, scheduleId, scheduleFormValues } = action.payload
  const studioOffset: number | undefined = yield select(getCurrentStudioOffset)
  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)

  const exercisesDTO = genScheduleExercisesEditDTO(scheduleFormValues, exercisesFilter, studioOffset)
  console.log('exercisesDTO', exercisesDTO)

  if (Object.keys(exercisesDTO.update).length !== 0) {
    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      yield callApi(apiV2.exercisesTimetableService.updateExercisesByTimetableId, scheduleId, exercisesDTO)
      yield put(scheduleLongtermPageModalEditActions.updateExercisesSuccess())
      yield put(modalActions.closeLast())
      yield put(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
          props: {
            studioId,
            scheduleId,
            action: 'EDIT',
          },
        })
      )
    } catch (e) {
      console.error(e)
      yield put(scheduleLongtermPageModalEditActions.updateExercisesError(new Error()))
    }
  } else {
    yield put(scheduleLongtermPageModalEditActions.updateExercisesSuccess())
    yield put(modalActions.closeLast())
  }
}

function* removeExercises(action: ReturnType<typeof scheduleLongtermPageModalEditActions.removeExercises>) {
  const { studioId, scheduleId } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(apiV2.exercisesTimetableService.removeExercisesByTimetableId, scheduleId, exercisesFilter)
    yield put(scheduleLongtermPageModalEditActions.removeExercisesSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: 'EDIT',
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPageModalEditActions.removeExercisesError(new Error()))
  }
}

export function* scheduleLongtermPageModalEditSagas() {
  yield takeLatest(scheduleLongtermPageModalEditActions.updateExercises, updateExercises)
  yield takeLatest(scheduleLongtermPageModalEditActions.removeExercises, removeExercises)
}
