import dayjs, { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentStudioOffset } from '../../../store/common/layout/layout.selectors'
import { NString } from '../../../types/lang.types'
import { ScheduleExercisesFilter } from './schedule-exercises-filters.types'
import {
  genScheduleExercisesFiltersBookingStatusOptions,
  genScheduleExercisesFiltersPaymentStatusOptions,
  genScheduleExercisesFiltersPaymentTypeOptions,
  genScheduleExercisesFiltersTimeScopeOptions,
} from './schedule-exercises-filters.utils'

export interface UseScheduleExercisesFiltersProps {
  dateFrom?: NString
  dateTo?: NString
  exercisesFilter: ScheduleExercisesFilter
  onSelectFilter: (name: string, value?: string | string[]) => void
  onResetFilter: () => void
}

export function useScheduleExercisesFilters(props: UseScheduleExercisesFiltersProps) {
  const { dateFrom, dateTo, exercisesFilter, onSelectFilter, onResetFilter } = props

  const studioOffset = useSelector(getCurrentStudioOffset)

  const [isFiltersOpen, setIsFiltersOpen] = useState(true)
  const [timeFilter, setTimeFilter] = useState<{ [key: string]: Dayjs | undefined }>({
    timeFrom: undefined,
    timeTo: undefined,
  })

  const disabledDate = (current: Dayjs) => {
    const startDate = dayjs(dateFrom)
    const endDate = dayjs(dateTo)

    return current.isBefore(startDate) || current.isAfter(endDate)
  }

  const paymentStatusOptions = useMemo(genScheduleExercisesFiltersPaymentStatusOptions, [])
  const paymentTypeOptions = useMemo(genScheduleExercisesFiltersPaymentTypeOptions, [])
  const timeScopeOptions = useMemo(genScheduleExercisesFiltersTimeScopeOptions, [])
  const bookingStatusOptions = useMemo(genScheduleExercisesFiltersBookingStatusOptions, [])

  const handleFiltersOpen = () => {
    setIsFiltersOpen(!isFiltersOpen)
  }

  const handleTrainersChange = (newValue: string | string[]) => {
    const prevValue = exercisesFilter.trainerIds
    if (typeof newValue === 'object') {
      if (prevValue?.includes('noTrainer')) {
        onSelectFilter(
          'trainerIds',
          newValue.filter(value => value !== 'noTrainer')
        )
      } else if (newValue.includes('noTrainer')) {
        onSelectFilter('trainerIds', ['noTrainer'])
      } else {
        onSelectFilter('trainerIds', newValue)
      }
    }
  }

  const handleStudioChange = (value: string | string[]) => {
    onSelectFilter('studioId', value)
    onSelectFilter('roomId', undefined)
  }

  const handleTimeChange = (value: Dayjs, name: string) => {
    setTimeFilter(prevTimeFilter => ({
      ...prevTimeFilter,
      [name]: value,
    }))
    if (value) {
      const timeZone = studioOffset ?? 0

      const time = value.subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
      onSelectFilter(name, time)
    } else {
      onSelectFilter(name, undefined)
    }
  }

  const handleResetFilter = () => {
    setTimeFilter({
      timeFrom: undefined,
      timeTo: undefined,
    })
    onResetFilter()
  }

  return {
    timeFilter,
    isFiltersOpen,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
    disabledDate,
    handleFiltersOpen,
    handleTrainersChange,
    handleStudioChange,
    handleTimeChange,
    handleResetFilter,
  }
}
