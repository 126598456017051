import { HttpConnector } from '../connectors/http.connector'

export class AuthorizationTicketsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchTicket = (): Promise<{
    data: string
  }> => {
    return this.httpConnector.post(`/authorization-tickets`)
  }
}
