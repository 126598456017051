import { call, put, take, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { Client } from '@stomp/stompjs'

import { websocketTimetableActions } from './websocket-timetable.slice'
import { createWebSocketChannel, getWebSocketTicket } from '../websocket.utils'
import { WebSocketResponse } from '../websocket.types'
import { WebsocketTimetableMessage } from './websocket-timetable.types'

let client: Client | null = null

export function* connect(action: ReturnType<typeof websocketTimetableActions.connect>) {
  try {
    const ticket: string = yield call(getWebSocketTicket)
    const timetableId = action.payload
    const destination = `/messages/timetable/${timetableId}/exercises`

    const { channel, client: newClient } = yield call(createWebSocketChannel, ticket, destination)
    client = newClient

    while (true) {
      const response: WebSocketResponse<WebsocketTimetableMessage> = yield take(channel)
      console.log('response:', response)
      if ('type' in response) {
        if (response.type === 'DISCONNECT') {
          yield put(websocketTimetableActions.disconnect())
          break
        } else if (response.type === 'ERROR') {
          yield put(websocketTimetableActions.connectionError(new Error()))
          notification.error({
            message: 'WebSocket Error',
          })
          break
        } else if (response.type === 'CONNECT') {
          yield put(websocketTimetableActions.connectionSuccess())
        }
      } else {
        console.log('Received message:', response)
        yield put(websocketTimetableActions.receiveMessage(response))
      }
    }
  } catch (error) {
    yield put(websocketTimetableActions.connectionError(new Error()))
  }
}

function* disconnect(_action: ReturnType<typeof websocketTimetableActions.disconnect>) {
  if (client) {
    yield call([client, 'deactivate'])
    client = null
  } else {
    console.warn('No WebSocket client found')
  }
}

export function* websocketTimetableSagas() {
  yield takeLatest(websocketTimetableActions.connect, connect)
  yield takeLatest(websocketTimetableActions.disconnect, disconnect)
}
