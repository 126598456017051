import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { getWebsocketTimetableMessages } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.selectors'
import { websocketTimetableActions } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.slice'
import { genScheduleLongtermPagePeekAboutExercisesTotalElement } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPagePeekAboutActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.slice'
import { AppModal } from '../../../types/modal.types'

export interface UseScheduleLongtermPageModalProgressProps {
  studioId: string
  scheduleId: string
  action: 'CREATE' | 'EDIT'
}

export function useScheduleLongtermPageModalProgress(props: UseScheduleLongtermPageModalProgressProps) {
  const { studioId, scheduleId, action } = props

  const dispatch = useDispatch()

  const totalExercises = useSelector(genScheduleLongtermPagePeekAboutExercisesTotalElement)
  const messages = useSelector(getWebsocketTimetableMessages)

  const onConflictResolutionHandler = useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFLICTS,
        props: {
          studioId,
          action,
        },
      })
    )
  }, [action, dispatch, studioId])

  const onCancelHandler = useCallback((): void => {
    dispatch(websocketTimetableActions.disconnect())
    dispatch(modalActions.closeLast())
    dispatch(websocketTimetableActions.resetMessages())
    dispatch(
      scheduleLongtermPagePeekAboutActions.fetchExercises({
        timetableId: scheduleId,
      })
    )
  }, [dispatch, scheduleId])

  return {
    totalExercises,
    messages,
    onConflictResolutionHandler,
    onCancelHandler,
  }
}
