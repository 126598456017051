import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { Form } from 'antd'

import { modalActions } from '../../../store/common/modal/modal.slice'
import {
  genScheduleLongtermPagePeekAboutTrainersOptions,
  genScheduleLongtermPagePeekAboutStudiosRoomsOptions,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPageModalAddActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-add/schedule-longterm-page-modal-add.slice'

export function useScheduleLongtermPageModalAdd(scheduleId: string) {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const trainersOptions = useSelector(genScheduleLongtermPagePeekAboutTrainersOptions)
  const roomsOptions = useSelector(genScheduleLongtermPagePeekAboutStudiosRoomsOptions)

  const onSaveHandler = useCallback(() => {
    const scheduleFormValues = form.getFieldsValue()
    dispatch(scheduleLongtermPageModalAddActions.addExercises({ studioId: '', scheduleId, scheduleFormValues }))
  }, [dispatch, form, scheduleId])

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return {
    form,
    trainersOptions,
    roomsOptions,
    onCancelHandler,
    onSaveHandler,
  }
}
