import { CustomCard } from '../../components/custom-card/custom-card.component'
import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { ScheduleLongtermPageFilter } from './schedule-longterm-page-filter/schedule-longterm-page-filter.component'
import { ScheduleLongtermPageTable } from './schedule-longterm-page-table/schedule-longterm-page-table.component'
import { ScheduleLongtermPageTitle } from './schedule-longterm-page-title/schedule-longterm-page-title.component'
import { useScheduleLongtermPage } from './schedule-longterm-page.hook'

export const ScheduleLongtermPage = () => {
  const { isLoaded, isLoading } = useScheduleLongtermPage()

  if (!isLoaded && isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <ScheduleLongtermPageTitle />
      <CustomCard>
        <ScheduleLongtermPageFilter />
        <ScheduleLongtermPageTable />
      </CustomCard>
    </>
  )
}
