import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { WebsocketTimetableMessage } from './websocket-timetable.types'

interface WebsocketTimetableState {
  isConnecting: boolean
  isConnected: boolean
  messages: WebsocketTimetableMessage[]
  error: Nullable<Error>
}

const initialState: WebsocketTimetableState = {
  isConnecting: false,
  isConnected: false,
  messages: [],
  error: null,
}

export const { actions: websocketTimetableActions, reducer: websocketTimetableReducer } = createSlice({
  name: '@@websocket-timetable',
  initialState,
  reducers: {
    connect: (state, _action: PayloadAction<string>) => {
      state.isConnecting = true
      state.error = null
    },
    connectionSuccess: state => {
      state.isConnecting = false
      state.isConnected = true
    },
    connectionError: (state, action: PayloadAction<Error>) => {
      state.isConnecting = false
      state.isConnected = false
      state.error = action.payload
    },
    receiveMessage: (state, action: PayloadAction<WebsocketTimetableMessage>) => {
      state.messages = [...state.messages, action.payload]
    },
    updateMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.map(message =>
        message.exercise.id === action.payload ? { ...message, error: null, conflicts: null } : message
      )
    },
    resetMessages: state => {
      state.messages = []
    },
    disconnect: state => {
      state.isConnecting = false
      state.isConnected = false
    },
  },
})
