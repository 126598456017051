import { LineChart } from './line-chart.components'
import { FunnelChart } from './funnel-chart.component'
import { StackedBarChart } from './stacked-bar-chart/stacked-bar-chart.component'
import { ProgressAnalytic } from './progress-analytic/progress-analytic.component'
import { TableAnalytic } from './table-analytic/table-analytic.component'
import { AnalyticsHeader } from './analytics-header/analytics-header.component'
import { AnalyTicsProvider } from './analytics-page.hook'
import { CheckAnalytics } from './check-analytics'
import styles from './analytics-page.module.scss'
import { StudioIncomeAnalytics } from './studio-income-analytics'
import { NewClientsAnalytics } from './new-clients-analytics'

export const AnalyticsPage = () => {
  return (
    <AnalyTicsProvider>
      <div className={styles.container}>
        <div className={styles.header}>
          <AnalyticsHeader />
        </div>
        {/* <FunnelChart /> */}
        <div className={styles.lines}>
          <LineChart />
          {/* <LineChart /> */}
        </div>
        <div className={styles.progresses}>
          <CheckAnalytics />
          <StudioIncomeAnalytics />
          <NewClientsAnalytics />
          {/* <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic />
        <ProgressAnalytic /> */}
        </div>
        {/* <TableAnalytic /> */}
        {/* <StackedBarChart /> */}
      </div>
    </AnalyTicsProvider>
  )
}
