import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  FormListFieldData,
  Collapse,
  Button,
  Flex,
  Form,
  Table,
  FormInstance,
  Typography,
  Input,
  Space,
  InputNumber,
  Tooltip,
  Alert,
} from 'antd'
import Select, { DefaultOptionType } from 'antd/es/select'
import { ChangeEvent, useCallback, useState } from 'react'

import { isDef, isDefAndNotEmpty, Nullable } from '../../../../../../types/lang.types'
import { Theme } from '../../../../../../types/theme.types'
import { MasterServicesPricingFormValues } from '../../../master-services-form.types'
import {
  genImpactTitle,
  genImpactTypeOptions,
  genImpactDirectionOptions,
} from './master-services-form-pricing-impact.utils'
import { EmployeesPositionsApi } from '../../../../../../api/types/employees-positions-api.types'
import { ConditionTypes, ImpactDirection, ImpactType } from '../../../../../../api/types/api.types'
import './master-services-form-pricing-impact.styles.less'
import { QuestionTooltip } from '../../../../../ui/question-tooltip/question-tooltip.component'
import { genDaysOptions } from '../../../../../../utils/days.utils'

interface Props {
  theme: Theme
  form: FormInstance<MasterServicesPricingFormValues>
  field: FormListFieldData
  index: number
  activeKeys: string[]
  positions?: Nullable<EmployeesPositionsApi.EmployeePosition[]>
  positionsOptions?: DefaultOptionType[]
  onRemove: (index: number | number[]) => void
  onCollapseChange: (key: string[]) => void
}

interface Condition {
  durationMinutes?: number
  value?: string
  positionId?: string
  gradeId?: string
  days?: string[]
  timeStart?: string
  timeEnd?: string
}

interface DataItem {
  type: 'DURATION' | 'GRADE' | 'TIME' | 'WITH_TRAINER'
  conditions: Condition[]
}

export const MasterServicesFormPricingImpact: React.FC<Props> = props => {
  const { theme, form, field, index, activeKeys, positions, positionsOptions, onRemove, onCollapseChange, ...rest } =
    props

  const [errorText, setErrorText] = useState<null | string>(null)

  const validateConditions = (data: DataItem[], index: number) => {
    const item = data[index]
    if (!item) return false
    const { type, conditions } = item

    switch (type) {
      case 'DURATION':
        return conditions.every(condition => condition?.durationMinutes !== undefined && condition?.value !== undefined)
      case 'GRADE':
        return conditions.every(condition => condition?.positionId !== undefined && condition?.value !== undefined)
      case 'TIME':
        return conditions.every(
          condition =>
            condition?.days !== undefined &&
            condition?.timeStart !== undefined &&
            condition?.timeEnd !== undefined &&
            condition?.value !== undefined
        )
      case 'WITH_TRAINER':
        return conditions.every(condition => condition?.value !== undefined)
      default:
        return false
    }
  }

  const handleActiveClick = useCallback(async () => {
    const fields = form.getFieldsValue(['priceImpacts'])

    if (validateConditions(fields?.priceImpacts, index)) {
      const filteredKeys = activeKeys.filter(key => key !== `${index}`)
      onCollapseChange(filteredKeys)
      setErrorText(null)
    } else {
      setErrorText('Необходимо заполнить все поля')
    }
  }, [activeKeys, index, onCollapseChange])

  const handleTimeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, i: number, fieldName: string) => {
      const newValue = e.target.value
      form.setFieldValue(['priceImpacts', index, 'conditions', i, fieldName], newValue)
    },
    [form, index]
  )

  const type = form.getFieldValue(['priceImpacts', index, 'type'])

  const conditions = Form.useWatch(['priceImpacts', index, 'conditions'], form)

  return (
    <Collapse.Panel
      {...rest}
      header={<Typography.Text strong>{genImpactTitle(type)}</Typography.Text>}
      key={`${index}`}
      className={`master-services-form-pricing-impact master-services-form-pricing-impact--${theme}`}
    >
      <Flex vertical gap="middle">
        <Form.List name={[field.name, 'conditions']} initialValue={[undefined]}>
          {(conditions, { add, remove }) => (
            <Flex vertical>
              {isDefAndNotEmpty(conditions) && (
                <Table
                  dataSource={conditions}
                  pagination={false}
                  className="master-services-form-pricing-impact__table"
                >
                  {type === ConditionTypes.TIME && (
                    <>
                      <Table.Column
                        dataIndex={'days'}
                        title={'Дни недели'}
                        width={'180px'}
                        render={(_v, _r, i) => (
                          <Form.Item name={[i, 'days']}>
                            <Select
                              size="large"
                              options={genDaysOptions()}
                              mode="multiple"
                              placeholder="Выбрать"
                              style={{ maxWidth: '180px' }}
                            />
                          </Form.Item>
                        )}
                      />
                      <Table.Column
                        dataIndex={'time'}
                        title={'Время'}
                        width={'auto'}
                        render={(_v, _r, i) => (
                          <Space size="middle">
                            <Form.Item name={[i, 'timeStart']}>
                              <Flex align="center" gap="small">
                                с{' '}
                                <Input
                                  type="time"
                                  size="large"
                                  value={form.getFieldValue(['priceImpacts', index, 'conditions', i, 'timeStart'])}
                                  onChange={e => handleTimeChange(e, i, 'timeStart')}
                                />
                              </Flex>
                            </Form.Item>
                            <Form.Item name={[i, 'timeEnd']}>
                              <Flex align="center" gap="small">
                                по{' '}
                                <Input
                                  type="time"
                                  size="large"
                                  value={form.getFieldValue(['priceImpacts', index, 'conditions', i, 'timeEnd'])}
                                  onChange={e => handleTimeChange(e, i, 'timeEnd')}
                                />
                              </Flex>
                            </Form.Item>
                          </Space>
                        )}
                      />
                    </>
                  )}
                  {type === ConditionTypes.GRADE && (
                    <>
                      <Table.Column
                        dataIndex={'positionId'}
                        title={'Должность'}
                        width={'170px'}
                        render={(_v, _r, i) => {
                          const onPositionChange = () => {
                            form.setFieldValue(['priceImpacts', index, 'conditions', i, 'gradeId'], undefined)
                          }

                          return (
                            <Form.Item name={[i, 'positionId']}>
                              <Select
                                size="large"
                                options={positionsOptions}
                                placeholder="Выбрать"
                                onChange={onPositionChange}
                                style={{ maxWidth: '170px' }}
                              />
                            </Form.Item>
                          )
                        }}
                      />
                      <Table.Column
                        dataIndex={'gradeId'}
                        title={'Грейд'}
                        width={'170px'}
                        render={(_v, _r, i) => {
                          const positionId = form.getFieldValue(['priceImpacts', index, 'conditions', i, 'positionId'])
                          const grades =
                            isDef(positions) &&
                            isDef(positionId) &&
                            positions.find(position => position.id === positionId)?.grades

                          const gradeOptions = grades
                            ? grades?.map(employeesGrade => ({
                                value: employeesGrade.id,
                                label: employeesGrade.name,
                              }))
                            : undefined

                          return (
                            <Form.Item name={[i, 'gradeId']}>
                              <Select
                                size="large"
                                placeholder="Выбрать"
                                options={gradeOptions}
                                disabled={!isDefAndNotEmpty(gradeOptions)}
                                style={{ maxWidth: '170px' }}
                              />
                            </Form.Item>
                          )
                        }}
                      />
                    </>
                  )}
                  {type === ConditionTypes.DURATION && (
                    <Table.Column
                      dataIndex={'durationMinute'}
                      title={'Длительность'}
                      width={'auto'}
                      render={(_v, _r, i) => (
                        <Form.Item name={[i, 'durationMinutes']}>
                          <InputNumber size="large" addonAfter="мин" placeholder="0" style={{ width: '100%' }} />
                        </Form.Item>
                      )}
                    />
                  )}
                  <Table.Column
                    dataIndex={'value'}
                    title={'Условие'}
                    width={type === ConditionTypes.WITH_TRAINER ? '50%' : 'auto'}
                    render={(_v, _r, i) => (
                      <Form.Item name={[i, 'value']} className="master-services-form-pricing-impact__addon-input">
                        <Input
                          size="large"
                          addonBefore={
                            <Form.Item
                              name={[i, 'impactDirection']}
                              style={{ width: '100px' }}
                              initialValue={ImpactDirection.DISCOUNT}
                            >
                              <Select options={genImpactDirectionOptions()} />
                            </Form.Item>
                          }
                          addonAfter={
                            <Form.Item
                              name={[i, 'impactType']}
                              style={{ minWidth: '60px' }}
                              initialValue={ImpactType.PERCENT}
                            >
                              <Select options={genImpactTypeOptions()} />
                            </Form.Item>
                          }
                          placeholder="0"
                        />
                      </Form.Item>
                    )}
                  />
                  <Table.Column
                    dataIndex={'nameInReceipt'}
                    width={'154px'}
                    title={() => (
                      <Flex gap="4px">
                        <Typography.Text strong>Название</Typography.Text>
                        <QuestionTooltip title="Заполнив это поле, вы зададите собственное наименование номенклатуры для этого условия. Именно это наименование будет отображаться в отчётах и чеках." />
                      </Flex>
                    )}
                    render={(_v, _r, i) => (
                      <Form.Item name={[i, 'nameInReceipt']}>
                        <Input size="large" />
                      </Form.Item>
                    )}
                  />
                  {type !== ConditionTypes.WITH_TRAINER && (
                    <Table.Column
                      dataIndex={'actions'}
                      render={(_v, _r, i) => (
                        <Tooltip title="Удалить">
                          <Button icon={<DeleteOutlined />} size="middle" onClick={e => remove(i)} />
                        </Tooltip>
                      )}
                    />
                  )}
                </Table>
              )}
              {type !== ConditionTypes.WITH_TRAINER && (
                <Button
                  type="link"
                  size="middle"
                  icon={<PlusCircleOutlined />}
                  onClick={() => add()}
                  style={{ alignSelf: 'baseline' }}
                >
                  Добавить {isDefAndNotEmpty(conditions) ? 'еще' : ''} условие
                </Button>
              )}
            </Flex>
          )}
        </Form.List>
        {errorText && <Alert message={errorText} type="error" showIcon />}
        <Flex gap="small">
          <Button type="primary" onClick={handleActiveClick} disabled={!isDefAndNotEmpty(conditions)}>
            Активировать
          </Button>
          <Button type="link" danger onClick={() => onRemove(index)}>
            Удалить
          </Button>
        </Flex>
      </Flex>
    </Collapse.Panel>
  )
}
