import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { peekActions } from '../../../store/common/peek/peek.slice'
import { genScheduleLongtermPageListTableDataItems } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.selectors'
import { AppPeek } from '../../../types/peek.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { useScheduleLongtermPageParams } from '../schedule-longterm-page-hooks/schedule-longterm-page-params.hook'
import { scheduleLongtermPageListActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.slice'

export function useScheduleLongtermPageTable() {
  const dispatch = useDispatch()
  const params = useScheduleLongtermPageParams()
  const { studioId } = params

  const schedule = useSelector(genScheduleLongtermPageListTableDataItems)

  const onEditHandler = useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_LONGTERM_PAGE_PEEK_ABOUT,
          props: { scheduleId: id, studioId },
        })
      )
    },
    [dispatch, studioId]
  )

  const onRemoveHandler = useCallback(
    (id: string): void => {
      const onSubmit = () => {
        dispatch(scheduleLongtermPageListActions.cancelSchedule(id))
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена длинной записи',
            text: 'Вы уверены, что хотите отменить длинную запись',
            onSubmit,
          },
        })
      )
    },
    [dispatch]
  )

  return {
    schedule,
    onEditHandler,
    onRemoveHandler,
  }
}
