import * as React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { genScheduleLongtermPageParams } from '../schedule-longterm-page.utils'
import { ScheduleLongtermPageQueryParams, ScheduleLongtermPageUrlParams } from '../schedule-longterm-page.types'

export function useScheduleLongtermPageParams() {
  const { params } = useRouteMatch<ScheduleLongtermPageUrlParams>()
  const { search } = useLocation<ScheduleLongtermPageQueryParams>()

  return React.useMemo(() => genScheduleLongtermPageParams(params, search), [params, search])
}
