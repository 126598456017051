import { put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { ScheduleOverview } from '../../../../components/schedule/schedule-overview/schedule-overview.types'
import { mapConflictsFormValuesToExerciseUpdateDTO } from '../../../../mapping/exercises.mapping'
import { isDef, Nullable } from '../../../../types/lang.types'
import { callApi } from '../../../../utils/sagas.utils'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { genScheduleLongtermPagePeekAboutSchedule } from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPageModalConflictsActions } from './schedule-longterm-page-modal-conflicts.slice'

export function* editExercise(action: ReturnType<typeof scheduleLongtermPageModalConflictsActions.editExercise>) {
  try {
    const { exerciseId, studioId, formValues, timeFrom, timeTo } = action.payload

    const schedule: Nullable<ScheduleOverview> = yield select(genScheduleLongtermPagePeekAboutSchedule)

    const exercisesUpdateDTO = mapConflictsFormValuesToExerciseUpdateDTO(
      formValues,
      studioId,
      timeFrom,
      timeTo,
      schedule?.direction?.id
    )

    if (isDef(exercisesUpdateDTO)) {
      yield callApi(api.exercisesService.update, exerciseId, exercisesUpdateDTO)
      yield put(websocketTimetableActions.updateMessage(exerciseId))
      yield put(scheduleLongtermPageModalConflictsActions.editExerciseSuccess())
    } else {
      yield put(scheduleLongtermPageModalConflictsActions.editExerciseError(new Error()))
    }
  } catch (e) {
    yield put(scheduleLongtermPageModalConflictsActions.editExerciseError(new Error()))
  }
}

export function* scheduleLongtermPageModalConflictsSagas() {
  yield takeLatest(scheduleLongtermPageModalConflictsActions.editExercise.type, editExercise)
}
