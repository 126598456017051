import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { genScheduleOverview, genScheduleExercises } from '../../../../mapping/exercises-timetable.mapping'
import { mapTrainersToOptions } from '../../../../mapping/trainers.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../../mapping/studios.mapping'

const genScheduleLongtermPagePeekAboutScheduleInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.timetable

const genScheduleLongtermPagePeekAboutExercisesInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.exercises

const getScheduleLongtermPagePeekAboutTrainersInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.trainers

export const genScheduleLongtermPagePeekAboutStudios = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.studios

export const genScheduleLongtermPagePeekAboutIsExercisesLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.isExercisesLoading

export const genScheduleLongtermPagePeekAboutIsTimetableLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.isTimetableLoading

export const genScheduleLongtermPagePeekAboutExercisesFilter = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.exercisesFilter

export const genScheduleLongtermPagePeekAboutSchedule = createSelector(
  genScheduleLongtermPagePeekAboutScheduleInternal,
  genScheduleOverview
)

export const genScheduleLongtermPagePeekAboutExercises = createSelector(
  genScheduleLongtermPagePeekAboutExercisesInternal,
  exercises => genScheduleExercises(exercises?.content)
)

export const genScheduleLongtermPagePeekAboutExercisesTotalElement = createSelector(
  genScheduleLongtermPagePeekAboutExercisesInternal,
  exercises => exercises?.totalElements
)

export const genScheduleLongtermPagePeekAboutTrainersOptions = createSelector(
  getScheduleLongtermPagePeekAboutTrainersInternal,
  trainers => mapTrainersToOptions(trainers?.content)
)

export const genScheduleLongtermPagePeekAboutStudiosOptions = createSelector(
  genScheduleLongtermPagePeekAboutStudios,
  studios => mapStudiosToOptions(studios?.content)
)

export const genScheduleLongtermPagePeekAboutStudiosRoomsOptions = createSelector(
  genScheduleLongtermPagePeekAboutStudios,
  studios => mapStudiosToRoomsOptions(studios?.content)
)
