import { Button, DatePicker, Select } from 'antd'

import { useAnalytics } from '../analytics-page.hook'
import { Period } from '../analytics-page.types'
import styles from './analytics-header.module.scss'

export const AnalyticsHeader = () => {
  const { period, setPeriod, range, setRange } = useAnalytics()

  return (
    <div className={styles.container}>
      <div className={styles.title}>Выберите период</div>
      <div className={styles.content}>
        <DatePicker.RangePicker
          // @ts-ignore
          value={range}
          onChange={value => {
            console.log('value = ', value)
            // @ts-ignore
            setRange(value)
          }}
          className={styles.range}
        />
        <Select
          value={period}
          className={styles.period}
          onChange={value => setPeriod(value)}
          options={[
            {
              label: 'По дням',
              value: Period.Day,
            },
            {
              label: 'По неделям',
              value: Period.Week,
            },
          ]}
        />
        <Button>Применить</Button>
      </div>
    </div>
  )
}
