import { AxiosResponse } from 'axios'

import { Nullable } from '../../types/lang.types'
import { HttpConnector } from '../connectors/http.connector'
import { Pagination } from '../types/api.types'
import { ExercisesTimetableApi } from '../types/exercises-timetable-api.types'

export class ExercisesTimetableService {
  constructor(private readonly httpConnector: HttpConnector) {}

  private createFetchExercisesParams = (
    params: Partial<ExercisesTimetableApi.ExercisesTimetableFetchExercisesParams>
  ): URLSearchParams => {
    const queryParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'trainerIds' && Array.isArray(value) && value.length === 1 && value[0] === 'noTrainer') {
        queryParams.append('trainerIds', '')
      } else if (value && !(Array.isArray(value) && value.length === 0)) {
        queryParams.append(key, String(value))
      }
    })

    return queryParams
  }

  private createRemoveExercisesParams = (
    params: Partial<ExercisesTimetableApi.ExercisesTimetableRemoveExercisesParams>
  ): URLSearchParams => {
    const queryParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        queryParams.append(key, String(value))
      }
    })

    return queryParams
  }

  public create = (
    data: ExercisesTimetableApi.ExercisesTimetableDTO
  ): Promise<AxiosResponse<ExercisesTimetableApi.ExercisesTimetable[]>> => {
    return this.httpConnector.post<ExercisesTimetableApi.ExercisesTimetable[]>('/exercises/timetable', data)
  }

  public fetchAll = (
    params?: Nullable<ExercisesTimetableApi.ExercisesTimetableFetchAllParams>
  ): Promise<AxiosResponse<ExercisesTimetableApi.ExercisesTimetable[]>> => {
    return this.httpConnector.get<ExercisesTimetableApi.ExercisesTimetable[]>('/exercises/timetable', { params })
  }

  public fetchById = (id: string): Promise<AxiosResponse<ExercisesTimetableApi.ExercisesTimetable>> => {
    return this.httpConnector.get<ExercisesTimetableApi.ExercisesTimetable>(`/exercises/timetable/${id}`)
  }

  public fetchBookingsByTimetableId = (
    timetableId: string,
    params?: Nullable<Partial<ExercisesTimetableApi.ExercisesTimetableFetchBookingsParams>>
  ): Promise<AxiosResponse<Pagination<ExercisesTimetableApi.ExercisesTimetableBooking>>> => {
    return this.httpConnector.get<Pagination<ExercisesTimetableApi.ExercisesTimetableBooking>>(
      `/exercises/timetable/${timetableId}/bookings`,
      { params }
    )
  }

  public update = (
    id: string,
    data: ExercisesTimetableApi.ExercisesTimetableDTO
  ): Promise<AxiosResponse<ExercisesTimetableApi.ExercisesTimetable>> => {
    return this.httpConnector.patch<ExercisesTimetableApi.ExercisesTimetable>(`/exercises/timetable/${id}`, data)
  }

  public cancel = (id: string, forceCancel?: boolean): Promise<AxiosResponse<void>> => {
    return this.httpConnector.delete<void>(`/exercises/timetable/${id}?forceCancel=true`)
  }

  // Only v.2
  public fetchExercisesByTimetableId = (
    timetableId: string,
    params: Partial<ExercisesTimetableApi.ExercisesTimetableFetchExercisesParams>
  ): Promise<AxiosResponse<Pagination<ExercisesTimetableApi.ExercisesTimetableExercise>>> => {
    const queryParams = this.createFetchExercisesParams(params)

    return this.httpConnector.get<Pagination<ExercisesTimetableApi.ExercisesTimetableExercise>>(
      `/exercises/timetable/${timetableId}/exercises?${queryParams.toString()}`
    )
  }

  public updateExercisesByTimetableId = (
    timetableId: string,
    data: ExercisesTimetableApi.ExercisesTimetableEditDTO
  ): Promise<AxiosResponse<{}>> => {
    return this.httpConnector.patch<ExercisesTimetableApi.ExercisesTimetable>(
      `/exercises/timetable/${timetableId}`,
      data
    )
  }

  public addExercisesByTimetableId = (
    timetableId: string,
    data: ExercisesTimetableApi.ExercisesTimetableAddDTO
  ): Promise<AxiosResponse<{}>> => {
    return this.httpConnector.post<ExercisesTimetableApi.ExercisesTimetable>(
      `/exercises/timetable/${timetableId}`,
      data
    )
  }

  public removeExercisesByTimetableId = (
    timetableId: string,
    params: Partial<ExercisesTimetableApi.ExercisesTimetableRemoveExercisesParams>
  ): Promise<AxiosResponse<{}>> => {
    const queryParams = this.createRemoveExercisesParams(params)

    return this.httpConnector.delete<ExercisesTimetableApi.ExercisesTimetable>(
      `/exercises/timetable/${timetableId}/exercises?${queryParams.toString()}`
    )
  }
}
