export const AUTH_ID = process.env.REACT_APP_AUTH_ID || 'React-auth-dev'

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.dev.vivacrm.ru/api/v1'

export const API_V1_1_URL = process.env.REACT_APP_API_V_1_1_URL || 'https://api.dev.vivacrm.ru/api/v1.1'

export const API_V2_URL = process.env.REACT_APP_API_V_2_URL || 'https://api.dev.vivacrm.ru/api/v2'

export const WS_V1_URL = process.env.REACT_APP_WS_V_1_URL || 'wss://api.dev.vivacrm.ru/ws/v1'

export const UPLOAD_URL = `${API_URL}/photos`

export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL || 'https://dev.kc.vivacrm.ru'

export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM || 'dev'

export const WIDGET_URL = process.env.REACT_APP_WIDGET_URL || 'https://dev.cabinet.vivacrm.ru/widget.js'
