import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ScheduleModalConflictsFormValues } from '../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.types'
import { formatPluralized } from '../../../format/text.format'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { getWebsocketTimetableConflicts } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.selectors'
import { WebsocketTimetableMessage } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.types'
import { getScheduleLongtermPageModalConflictsIsLoading } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-conflicts/schedule-longterm-page-modal-conflicts.selectors'
import { scheduleLongtermPageModalConflictsActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-conflicts/schedule-longterm-page-modal-conflicts.slice'
import {
  genScheduleLongtermPagePeekAboutStudiosRoomsOptions,
  genScheduleLongtermPagePeekAboutTrainersOptions,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { AppModal } from '../../../types/modal.types'

export function useScheduleLongtermPageModalConflicts(studioId: string) {
  const dispatch = useDispatch()

  const isLoading = useSelector(getScheduleLongtermPageModalConflictsIsLoading)
  const conflictMessages = useSelector(getWebsocketTimetableConflicts)
  const studiosRoomsOptions = useSelector(genScheduleLongtermPagePeekAboutStudiosRoomsOptions)
  const trainersOptions = useSelector(genScheduleLongtermPagePeekAboutTrainersOptions)

  const [conflicts, setConflicts] = useState<WebsocketTimetableMessage[]>(
    conflictMessages.map(message => ({
      ...message,
      shouldUpdated: true,
    }))
  )

  const onEditHandler = useCallback(
    (exerciseId: string, formValues: ScheduleModalConflictsFormValues, timeFrom: string, timeTo: string) => {
      dispatch(
        scheduleLongtermPageModalConflictsActions.editExercise({
          studioId,
          exerciseId,
          formValues,
          timeFrom,
          timeTo,
        })
      )
    },
    [dispatch, studioId]
  )

  const onSkipHandler = useCallback((exerciseId: string): void => {
    setConflicts(prevConflicts =>
      prevConflicts.map(conflict =>
        conflict.exercise.id === exerciseId ? { ...conflict, shouldUpdated: false } : conflict
      )
    )
  }, [])

  const onCancelHandler = useCallback((): void => {
    const onSubmit = () => {
      dispatch(modalActions.close(AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFLICTS))
    }

    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM,
        props: {
          title: 'Вы уверены что хотите завершить?',
          text: `${formatPluralized(conflicts?.length, ['запись', 'записи', 'записей'])} не ${
            conflicts?.length === 1 ? 'будет изменена' : 'будут изменены'
          } из-за конфликтов.`,
          onSubmit,
        },
      })
    )
  }, [conflicts?.length, dispatch])

  useEffect(() => {
    if (conflictMessages?.length === 0) {
      const newConflicts = conflictMessages.map(message => ({
        ...message,
        shouldUpdated: true,
      }))
      setConflicts(newConflicts)
    } else {
      const updatedConflicts = conflictMessages.map(newMessage => {
        const existingConflict = conflicts.find(conflict => conflict.exercise.id === newMessage.exercise.id)
        return existingConflict ? { ...existingConflict, ...newMessage } : { ...newMessage, shouldUpdated: true }
      })

      const filteredConflicts = updatedConflicts.filter(existingConflict =>
        conflictMessages.some(newMessage => newMessage.exercise.id === existingConflict.exercise.id)
      )

      setConflicts(filteredConflicts)
    }
  }, [conflictMessages])

  useEffect(() => {
    const conflictsShouldUpdated = conflicts.filter(conflict => conflict.shouldUpdated)
    if (conflictsShouldUpdated?.length === 0) {
      dispatch(modalActions.closeLast())
    }
  }, [conflicts, dispatch])

  return {
    isLoading,
    studioId,
    conflicts,
    studiosRoomsOptions,
    trainersOptions,
    onEditHandler,
    onCancelHandler,
    onSkipHandler,
  }
}
