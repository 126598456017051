import { Button, Divider, Flex, Modal } from 'antd'

import { ScheduleModalConflictsProps } from './schedule-modal-conflicts.types'
import { ScheduleModalConflictsExercise } from './schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.component'
import './schedule-modal-conflicts.styles.less'

export const ScheduleModalConflicts: React.FC<ScheduleModalConflictsProps> = props => {
  const { studioId, action, isLoading, conflicts, trainersOptions, studiosRoomsOptions, onEdit, onSkip, onCancel } =
    props

  return (
    <Modal
      className="schedule-modal-conflicts"
      title="Решение конфликтов"
      visible={true}
      width={560}
      onCancel={onCancel}
      footer={
        <Flex justify="center">
          <Button loading={isLoading} type="primary" onClick={onCancel}>
            Завершить {action === 'CREATE' ? 'создание' : 'редактирование'} длинной записи
          </Button>
        </Flex>
      }
    >
      <Flex vertical gap={16}>
        <Divider className="schedule-modal-conflicts__divider" />
        {conflicts
          ?.filter(conflict => conflict.shouldUpdated)
          ?.map(conflict => (
            <ScheduleModalConflictsExercise
              isLoading={isLoading}
              studioId={studioId}
              action={action}
              conflict={conflict}
              trainersOptions={trainersOptions}
              studiosRoomsOptions={studiosRoomsOptions}
              onEditHandler={onEdit}
              onSkipHandler={onSkip}
            />
          ))}
      </Flex>
    </Modal>
  )
}
