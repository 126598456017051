import dayjs, { Dayjs } from 'dayjs'

import { isDef, NString } from '../types/lang.types'
import { formatPluralized } from './text.format'

export function genDefaultDateFormat(): string {
  return 'DD.MM.YYYY'
}

export function genDefaultTimeFormat(): string {
  return 'HH:mm'
}

export function genDefaultDayjsFormat(): string {
  return 'YYYY-MM-DD'
}

export function formatToUTCHours(date: NString, format: string = genDefaultTimeFormat(), offsetArg?: number): NString {
  if (isDef(date)) {
    const offset = offsetArg || dayjs(date).utcOffset()
    return dayjs(date).utcOffset(offset).format(format)
  }

  return null
}

export function formatToHHmm(date: string): string {
  return dayjs(date, 'HH:mm:ss').format(genDefaultTimeFormat())
}

export function formatDayjsToDate(dayjs: Dayjs): string {
  return dayjs.format(genDefaultDayjsFormat())
}

export function formatDate(
  date: string,
  format: string = genDefaultDateFormat(),
  originalFormat: string = 'YYYY-MM-DDTHH:mm'
): string {
  dayjs.locale('ru')
  return dayjs(date, originalFormat).format(format)
}

export function formatTime(date: string, format: string = genDefaultTimeFormat()): string {
  return dayjs(date).format(format)
}

export function formatDateTime(date: string, time: string, offset: number) {
  return dayjs(`${date}T${time}`).utcOffset(offset, true).format('YYYY-MM-DDTHH:mmZ')
}

export function formatTimeToHhMmSs(date: string): string {
  return formatTime(date, 'HH:mm:ss')
}

export function formatDateToHumanize(date: string): string {
  dayjs.locale('ru')
  return dayjs(date).format('D MMMM YYYY')
}

export function formatIsoStringTOHhMm(date: string): string {
  return dayjs.utc(dayjs.duration(date).asMilliseconds()).format('HH:mm')
}

export function formatIsoStringTOMinutes(date: string): number {
  return dayjs.duration(formatDateToMilliseconds(date), 'milliseconds').asMinutes()
}

export function formatIsoStringTOHours(date: string): number {
  return dayjs.duration(formatDateToMilliseconds(date), 'milliseconds').asHours()
}

export function formatIsoStringToDays(date: string): number {
  return dayjs.duration(formatDateToMilliseconds(date), 'milliseconds').asDays()
}

export function formatDurationText(date: string): string {
  const duration = dayjs.duration(formatDateToMilliseconds(date), 'milliseconds')

  const hours = duration.hours()
  const minutes = duration.minutes()

  const hoursStr = hours ? formatPluralized(hours, ['час', 'часа', 'часов']) : ''
  const minutesStr = minutes ? formatPluralized(minutes, ['минута', 'минуты', 'минут']) : ''

  const resultHoursStr = minutesStr ? `${hoursStr} ` : hoursStr

  return `${resultHoursStr}${minutesStr}`
}

export function formatHhMmToIsoString(date: string): string {
  return dayjs.duration(date).toISOString()
}

export function formatMinutesToIsoString(minutes: number): string {
  return dayjs.duration(minutes, 'minutes').toISOString()
}

export function formatHoursToMinutes(hours: number): number {
  return dayjs.duration(hours, 'hours').asMinutes()
}

export function formatMinutesToHours(minutes: number): number {
  return dayjs.duration(minutes, 'minutes').asHours()
}

export function formatDateToMilliseconds(date: string): number {
  return dayjs.duration(date).as('milliseconds')
}

export function formatTimeDifference(timeFrom: string, timeTo: string) {
  const startTime = dayjs(timeFrom)
  const endTime = dayjs(timeTo)

  const differenceInMinutes = endTime.diff(startTime, 'minute')

  const hours = Math.floor(differenceInMinutes / 60)
  const minutes = differenceInMinutes % 60

  const parts: string[] = []
  if (hours > 0) {
    parts.push(`${hours} час${hours > 1 ? 'а' : ''}`)
  }
  if (minutes > 0) {
    parts.push(`${minutes} мин`)
  }

  return parts.join(' ')
}
