import { Rule } from 'antd/lib/form'

import { ConditionTypes } from '../../../../api/types/api.types'

export function genMasterServicesPricingFormInitialValues() {
  return {
    priceType: ConditionTypes.NONE,
    holidayAsWeekend: false,
  }
}

export function genPricingTypeOptions() {
  return [
    { label: 'По дням неделям и времени', value: ConditionTypes.TIME },
    { label: 'По должности', value: ConditionTypes.GRADE },
    { label: 'По длительности', value: ConditionTypes.DURATION },
    { label: 'Фиксированное', value: ConditionTypes.NONE },
  ]
}

export function genMasterServicesFormPricingValidationRules(): Rule[] {
  return [{ required: true, message: 'Укажите название ценообразования' }]
}
