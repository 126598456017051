import { isString } from 'antd/es/button'
import qs from 'qs'

import { isDef, NString } from '../../types/lang.types'
import {
  ScheduleLongtermPageParams,
  ScheduleLongtermPageQueryParams,
  ScheduleLongtermPageUrlParams,
} from './schedule-longterm-page.types'

export function genScheduleLongtermPageParams(
  params: ScheduleLongtermPageUrlParams,
  search: NString
): ScheduleLongtermPageParams {
  return {
    ...genScheduleLongtermPageUrlParams(params),
    ...genSchedulePageQueryParams(search),
  }
}

export function genScheduleLongtermPageUrlParams(params: ScheduleLongtermPageUrlParams) {
  const { studioId, scheduleId } = params

  return { studioId, scheduleId }
}

function genSchedulePageQueryParams(search: NString): ScheduleLongtermPageQueryParams {
  const queryParams = isDef(search) ? qs.parse(search, { ignoreQueryPrefix: true }) : null

  const roomId = isString(queryParams?.roomId) ? queryParams?.roomId : null

  return {
    roomId,
  }
}
