import { Card, Button, Tag, Typography, Flex, Row, Col } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import './schedule-list-card.styles.less'
import { formatPhoneNumber } from '../../../../format/phone.format'
import { formatPaymentType, formatPluralized } from '../../../../format/text.format'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { ScheduleListCardProps } from './schedule-list-card.types'
import { PaymentType } from '../../../../types/payment.types'

const { Text } = Typography

export const ScheduleListCard: React.FC<ScheduleListCardProps> = props => {
  const { scheduleItem, onEdit, onRemove } = props

  let paymentTypeTagColor = ''
  switch (scheduleItem.paymentType) {
    case 'SUBSCRIPTION':
      paymentTypeTagColor = 'magenta'
      break
    case 'ONE_TIME':
      paymentTypeTagColor = 'geekblue'
      break
    case 'ON_PLACE':
      paymentTypeTagColor = 'orange'
      break
  }

  const onEditHandler = () => onEdit(scheduleItem.id)
  const onRemoveHandler = () => onRemove(scheduleItem.id)

  return (
    <Card className="schedule-list-card">
      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col style={{ flex: '1 1 240px' }}>
          <Flex vertical align="start" gap="4px">
            <Text style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
              {formatPluralized(scheduleItem.totalExercises, ['запись', 'записи', 'записей'])}
            </Text>
            <Text>{scheduleItem.direction.title}</Text>
            {scheduleItem.format === 'PERSONAL' && <Tag color="cyan">Персональная услуга</Tag>}
          </Flex>
        </Col>

        <Col flex="150px">
          <Typography style={{ color: '#1677ff', cursor: 'pointer' }}>
            {formatPhoneNumber(scheduleItem.clients)}
          </Typography>
        </Col>

        <Col flex="auto">
          <Flex vertical gap="10px" align="baseline">
            <Tag color={paymentTypeTagColor}>{formatPaymentType(scheduleItem.paymentType)}</Tag>
            {scheduleItem.paymentType === PaymentType.ON_PLACE && (
              <Button size="small" type="dashed" className="table-cell-tag__button" disabled>
                Создать оплату
              </Button>
            )}
          </Flex>
        </Col>

        <Col flex="auto">
          {isDefAndNotEmpty(scheduleItem.trainers) ? (
            <div>{scheduleItem.trainers.map(it => it.title).join(', ')}</div>
          ) : (
            'Без исполнителя'
          )}
        </Col>
        <Col flex="auto">
          <Flex vertical align="start" gap="4px">
            <Tag color={scheduleItem.studioRooms[0].color}>{scheduleItem.studioRooms[0].name}</Tag>
            {scheduleItem.studioRooms.length > 1 && <Tag>{scheduleItem.studioRooms.length - 1}</Tag>}
          </Flex>
        </Col>
        <Col flex="auto">
          <Flex vertical style={{ fontSize: '12px', color: '#000000A6' }}>
            <Text>С {dayjs(scheduleItem.dateFrom).format('DD MMMM YYYY')}</Text>
            <Text>по {dayjs(scheduleItem.dateTo).format('DD MMMM YYYY')}</Text>
            {/* <Text style={{ fontSize: '10px', color: '#00000073' }}>Осталось {scheduleItem.remaining} записи</Text> */}
          </Flex>
        </Col>
        <Col style={{ width: '104px' }}>
          <Flex justify="end" gap="8px">
            <Button icon={<EditOutlined />} onClick={onEditHandler} />
            <Button danger icon={<DeleteOutlined />} onClick={onRemoveHandler} />
          </Flex>
        </Col>
      </Row>
    </Card>
  )
}
