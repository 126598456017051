import { ScheduleModalEdit } from '../../../components/schedule/schedule-modal-edit/schedule-modal-edit.component'
import { useScheduleLongtermPageModalEdit } from './schedule-longterm-page-modal-edit.hook'
import { ScheduleLongtermPageModalEditProps } from './schedule-longterm-page-modal-edit.types'

export const ScheduleLongtermPageModalEdit: React.FC<ScheduleLongtermPageModalEditProps> = props => {
  const { studioId, scheduleId } = props

  const {
    form,
    formValues,
    isLoading,
    totalExercises,
    studiosOptions,
    studiosRoomsOptions,
    trainersOptions,
    onEditExercisesHandler,
    onRemoveExercisesHandler,
    onCancelHandler,
  } = useScheduleLongtermPageModalEdit(studioId, scheduleId)

  return (
    <ScheduleModalEdit
      form={form}
      studioId={studioId}
      initialValues={formValues}
      isLoading={isLoading}
      totalExercises={totalExercises}
      studiosOptions={studiosOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      trainersOptions={trainersOptions}
      onEditExercises={onEditExercisesHandler}
      onRemoveExercises={onRemoveExercisesHandler}
      onCancel={onCancelHandler}
    />
  )
}
