import { Form, Row, Col, FormListFieldData, FormListOperation, DatePicker, Flex, Typography } from 'antd'
import clsx from 'clsx'

import { genDefaultDateFormat } from '../../../format/date.format'
import { Days } from '../../../types/days.types'
import { getStrEnumValues } from '../../../utils/enum.utils'
import { DaysDropdown } from '../../controls/days-dropdown/days-dropdown.component'
import { DaysSelectBar } from '../../controls/days-select-bar/days-select-bar.component'
import { ScheduleSlotsAdd } from './schedule-slots-add/schedule-slots-add.component'
import { ScheduleSlotsHeader } from './schedule-slots-header/schedule-slots-header.component'
import { ScheduleSlotsItem } from './schedule-slots-item/schedule-slots-item.component'
import { ScheduleSlotsProps } from './schedule-slots.types'
import { useScheduleSlots } from './schedule-slots.hook'
import './schedule-slots.styles.less'

export const ScheduleSlots: React.FC<ScheduleSlotsProps> = props => {
  const { isLoading, isDisabled = false, form } = props
  const { trainersOptions, studiosRoomsOptions } = props

  const { timeRangePlaceholder, currentDay, dateRange, changeCurrentDay, handleDateChange, onCopyHandler } =
    useScheduleSlots({ form })

  return (
    <Flex vertical gap={16}>
      <Typography.Text>Расписание записей</Typography.Text>
      <Form.Item label="День недели">
        <Row gutter={16} justify="space-between">
          <Col span={12}>
            <DaysSelectBar disabled={isDisabled} value={currentDay} onChange={changeCurrentDay} />
          </Col>
          <Col span={12}>
            <DaysDropdown disabled={isDisabled} label="Скопировать расписание из" onClick={onCopyHandler} />
          </Col>
        </Row>
      </Form.Item>

      {getStrEnumValues<Days>(Days).map(day => (
        <div key={day} className={clsx({ 'schedule-slots_hidden': day !== currentDay })}>
          <Form.List name={['slots', day]}>
            {(fields: FormListFieldData[], { add, remove }: FormListOperation, { errors }) => (
              <>
                <ScheduleSlotsHeader />

                {fields.map((field: FormListFieldData) => {
                  const { key, name } = field

                  return (
                    <ScheduleSlotsItem
                      key={key}
                      day={day}
                      form={form}
                      name={name}
                      disabled={isDisabled}
                      loading={isLoading}
                      trainersOptions={trainersOptions}
                      studiosRoomsOptions={studiosRoomsOptions}
                      onRemove={remove}
                    />
                  )
                })}

                <ScheduleSlotsAdd disabled={isDisabled} onAdd={add} />

                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </div>
      ))}

      <Form.Item
        required
        className="schedule-slots__time"
        label="Время начала и окончания"
        name="date"
        rules={[
          {
            validator: (_, value) => {
              if (!value[0] && !value[1]) return Promise.reject('Выберите дату начала и окончания')
              // if (value[0].isSame(value[1])) return Promise.reject('Время начала не может быть равно времени окончания')

              return Promise.resolve()
            },
          },
        ]}
      >
        <DatePicker.RangePicker
          disabled={isDisabled}
          value={dateRange}
          onChange={handleDateChange}
          placeholder={timeRangePlaceholder}
          format={genDefaultDateFormat()}
        />
      </Form.Item>
    </Flex>
  )
}
