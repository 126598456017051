import { useAnalytics } from './analytics-page.hook'
import { ProgressAnalytic } from './progress-analytic/progress-analytic.component'

export const NewClientsAnalytics = () => {
  const { newClients, isLoading } = useAnalytics()
  if (!newClients && !isLoading) {
    return null
  }
  return (
    <ProgressAnalytic
      noCurrency
      title="Новые клиенты"
      value={newClients?.summary || 0}
      isLoading={isLoading}
      max={newClients?.max || 0}
      last={newClients?.last || 0}
      yesterday={newClients?.yesterday || 0}
    />
  )
}
