import { formatBookingStatus } from '../../../format/text.format'
import { BookingStatus } from '../../../types/bookings.types'
import { getStrEnumValues } from '../../../utils/enum.utils'

export function genScheduleExercisesFiltersPaymentStatusOptions() {
  return [
    { label: 'Оплачено', value: 'PAID' },
    { label: 'Не оплачено', value: 'UNPAID' },
    { label: 'Все', value: 'All' },
  ]
}

export function genScheduleExercisesFiltersTimeScopeOptions() {
  return [
    { label: 'Запланированные', value: 'FUTURE' },
    { label: 'Прошедшие', value: 'PAST' },
    { label: 'Все', value: 'All' },
  ]
}

export function genScheduleExercisesFiltersPaymentTypeOptions() {
  return [
    { label: 'Абонемент', value: 'SUBSCRIPTION' },
    { label: 'Оплата на месте', value: 'ON_PLACE' },
    { label: 'Ожидаем оплату', value: 'RESERVED' },
    { label: 'Единоразовая оплата', value: 'ONE_TIME' },
  ]
}

export function genScheduleExercisesFiltersBookingStatusOptions() {
  const bookingStatuses = getStrEnumValues<BookingStatus>(BookingStatus)

  return bookingStatuses.map(status => ({
    label: formatBookingStatus(status),
    value: status,
  }))
}
