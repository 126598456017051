import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import {
  ReceiptData,
  ReceiptsStatus,
  TransactionStatusAction,
  TransactionStatusItem,
} from './transactions-create-page-statuses.types'

export interface TransactionsCreatePageStatusesState {
  data: Record<string, TransactionStatusItem>
  receipts: string[]
}

const initialState: TransactionsCreatePageStatusesState = {
  data: {},
  receipts: [],
}

export const { actions: transactionsCreatePageStatusesActions, reducer: transactionsCreatePageStatusesReducer } =
  createSlice({
    name: '@@transactions-create-page-submit',
    initialState,
    reducers: {
      updateTransactionsStatus: (
        state: Draft<TransactionsCreatePageStatusesState>,
        action: PayloadAction<TransactionStatusItem>
      ) => {
        state.data[action.payload.transactionId] = action.payload
      },

      updateReceiptsState: (state: Draft<TransactionsCreatePageStatusesState>, action: PayloadAction<ReceiptData>) => {
        const status = action.payload.status
        const actionState = action.payload.action

        if (actionState === TransactionStatusAction.FINISHED && status === ReceiptsStatus.POLLING_RECEIPT) {
          state.receipts.push(action.payload.receiptId)
        }
      },

      reset: () => initialState,
    },
  })
