import { CheckOutlined, ReloadOutlined } from '@ant-design/icons'
import { Card, Flex, Typography, Row, Col, Select, Button, Switch, Tag, Input, Form, Space, Radio } from 'antd'
import { Link } from 'react-router-dom'

import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { ScheduleModalConflictsExerciseProps } from './schedule-modal-conflicts-exercise.types'
import { genScheduleGroupPagePath } from '../../../../format/path.format'
import { useScheduleModalConflictsExercise } from './schedule-modal-conflicts-exercise.hook'
import './schedule-modal-conflicts-exercise.styles.less'

export const ScheduleModalConflictsExercise: React.FC<ScheduleModalConflictsExerciseProps> = props => {
  const { isLoading, studioId, action, conflict, trainersOptions, studiosRoomsOptions, onEditHandler, onSkipHandler } =
    props

  const { form, startDate, endDate, trainerNames, onFinish } = useScheduleModalConflictsExercise({
    conflict,
    onEditHandler,
  })

  return (
    <Card className="schedule-modal-conflict">
      <Form form={form} onFinish={onFinish} disabled={isLoading}>
        <Flex vertical gap={16}>
          <div>
            <Typography.Title style={{ margin: 0 }} level={5}>
              {startDate.format('D MMMM')}, с {startDate.format('HH:mm')} до {endDate.format('HH:mm')}
            </Typography.Title>
            <Typography.Text>{trainerNames}</Typography.Text>
          </div>
          <Form.List name="conflicts">
            {(fields, { add, remove }) => (
              <Flex vertical gap={16}>
                {fields.map((field, index) => {
                  const conflictingExercise = conflict.conflicts?.[index]
                  const trainers = conflictingExercise?.trainers

                  return (
                    <Flex vertical gap={16} key={field.key}>
                      {conflictingExercise && isDefAndNotEmpty(trainers) && (
                        <Flex vertical gap={16}>
                          <Typography.Text type="danger">
                            {trainers.length === 1 ? 'Исполнитель' : 'Исполнители'}{' '}
                            {trainers.map(trainer => trainer.name).join(', ')} есть в другой{' '}
                            <Link
                              to={genScheduleGroupPagePath({
                                studioId,
                                scheduleId: conflictingExercise.conflictingExerciseId,
                              })}
                            >
                              записи
                            </Link>
                          </Typography.Text>
                          <Card className="schedule-modal-conflict">
                            <Flex vertical gap={8}>
                              <Form.Item {...field} name={[field.name, 'noTrainer']} valuePropName="checked">
                                <Space align="center" size="small">
                                  <Switch />
                                  <Typography>Без исполнителя</Typography>
                                </Space>
                              </Form.Item>

                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => prevValues.conflicts !== curValues.conflicts}
                              >
                                {({ getFieldValue }) => {
                                  const noTrainer = getFieldValue(['conflicts', field.name, 'noTrainer'])
                                  return !noTrainer ? (
                                    <>
                                      <Row gutter={24}>
                                        <Col span={12}>
                                          <Typography.Text strong>Исполнитель</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                          <Typography.Text strong>Выберите новое значение</Typography.Text>
                                        </Col>
                                      </Row>
                                      {conflictingExercise.trainers?.map((trainer, trainerIndex) => (
                                        <Row gutter={24} key={trainerIndex}>
                                          <Col span={12}>
                                            <Input disabled value={trainer.name} />
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item
                                              name={[field.name, 'trainers', trainerIndex]}
                                              rules={[{ required: true, message: 'Пожалуйста, выберите значение' }]}
                                            >
                                              <Select placeholder="Выберите новое значение" options={trainersOptions} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      ))}
                                    </>
                                  ) : null
                                }}
                              </Form.Item>
                            </Flex>
                          </Card>
                        </Flex>
                      )}
                      {conflictingExercise?.room && (
                        <Flex vertical gap={16}>
                          <Typography.Text type="danger">
                            Время в {conflictingExercise.room?.name} занято другой{' '}
                            <Link
                              to={genScheduleGroupPagePath({
                                studioId,
                                scheduleId: conflictingExercise.conflictingExerciseId,
                              })}
                            >
                              записью
                            </Link>
                          </Typography.Text>
                          <div className="schedule-modal-conflict__rooms">
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) => prevValues.conflicts !== curValues.conflicts}
                            >
                              {({ getFieldValue }) => {
                                const roomId = getFieldValue(['conflicts', field.name, 'room'])

                                return (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'room']}
                                    rules={[{ required: true, message: 'Пожалуйста, выберите значение' }]}
                                  >
                                    <Radio.Group className="schedule-modal-conflict__radio-group">
                                      <Flex vertical gap={16}>
                                        {studiosRoomsOptions
                                          ?.filter(
                                            room =>
                                              room.value !== conflictingExercise.room?.id &&
                                              room.studioId === conflict.exercise.studio.id
                                          )
                                          ?.map(room => (
                                            <Flex key={room.value} justify="space-between" align="center">
                                              <Tag className="schedule-modal-conflict__tag schedule-modal-conflict__tag--dashed">
                                                {room.label}
                                              </Tag>
                                              <Radio
                                                className="schedule-modal-conflict__radio-button"
                                                value={room.value}
                                              >
                                                <Tag className="schedule-modal-conflict__tag">
                                                  {roomId === room.value ? (
                                                    <>
                                                      <CheckOutlined /> Выбрано
                                                    </>
                                                  ) : (
                                                    'Выбрать'
                                                  )}
                                                </Tag>
                                              </Radio>
                                            </Flex>
                                          ))}
                                      </Flex>
                                    </Radio.Group>
                                  </Form.Item>
                                )
                              }}
                            </Form.Item>
                          </div>
                        </Flex>
                      )}
                    </Flex>
                  )
                })}
              </Flex>
            )}
          </Form.List>

          <Flex gap={16}>
            <Button style={{ width: '100%' }} onClick={() => onSkipHandler(conflict.exercise.id)}>
              Не {action === 'CREATE' ? 'создавать' : 'редактировать'} запись
            </Button>
            <Button icon={<ReloadOutlined />} style={{ width: '100%' }} htmlType="submit">
              Сохранить
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Card>
  )
}
