import dayjs from 'dayjs'

import { Days as DaysApi } from '../api/types/api.types'
import { ExercisesTimetableApi } from '../api/types/exercises-timetable-api.types'
import { StudiosApi } from '../api/types/studios-api.types'
import { ScheduleEditExercisesFormValues } from '../components/schedule/schedule-edit-exercises-form/schedule-edit-exercises-form.types'
import { ScheduleExercisesFilter } from '../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { ScheduleExercise } from '../components/schedule/schedule-exercises-table/schedule-exercises-table.types'
import {
  ScheduleFormSlotsMap,
  ScheduleFormValues,
  ScheduleFormValuesDTO,
} from '../components/schedule/schedule-form/schedule-form.types'
import { ScheduleListDataItem } from '../components/schedule/schedule-list/schedule-list.types'
import { ScheduleOverview } from '../components/schedule/schedule-overview/schedule-overview.types'
import { ScheduleAddSlotsFormValues, ScheduleSlot } from '../components/schedule/schedule-slots/schedule-slots.types'
import {
  ScheduleTableDataItem,
  ScheduleTableDataItemTimeSlot,
} from '../components/schedule/schedule-table/schedule-table.types'
import { formatTimeDifference } from '../format/date.format'
import { formatFormValueToPhoneNumber } from '../format/phone.format'
import { Days } from '../types/days.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { validateStrEnumValue } from '../utils/enum.utils'
import { mapDictionaryItemsListToEntityItemsList, mapDictionaryItemToEntityItem } from './api.mapping'

export function genExercisesTimetableDTO(
  values: Nullable<ScheduleFormValuesDTO>
): Nullable<ExercisesTimetableApi.ExercisesTimetableDTO> {
  if (isDef(values)) {
    const { date } = values
    const [dateFrom, dateTo] = date

    const clients =
      values.phone && values.paymentType
        ? [
            {
              spot: 1,
              phone: formatFormValueToPhoneNumber(values.phone),
              paymentType: values.paymentType,
            },
          ]
        : []
    const directionField = values.direction && !values.subService ? values.direction : values.subService
    if (isDef(dateFrom) && isDef(dateTo)) {
      return {
        comment: values.comment,
        // bookingClients: values.bookingClients,
        bookingClients: clients,
        forceCancel: values.bookClient,
        bookClient: values.bookClient,
        direction: directionField !== undefined ? directionField : 0,
        type: values.type,
        timeslots: genExercisesTimetableSlotsMapDTO(values.slots),
        dateFrom,
        dateTo,
      }
    }
  }

  return null
}

function genExercisesTimetableSlotsMapDTO(
  slots: ScheduleFormSlotsMap
): ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO {
  return Object.keys(slots).reduce<ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO>(
    (acc: ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO, key: string) => {
      const day = validateStrEnumValue<DaysApi>(DaysApi, key)

      if (isDef(day)) {
        const values = slots[day]?.reduce<ExercisesTimetableApi.ExercisesTimetableSlotDTO[]>((acc, slot) => {
          const exercisesTimetableSlotDTO = genExercisesTimetableSlotDTO(slot)

          if (isDef(exercisesTimetableSlotDTO)) {
            acc.push(exercisesTimetableSlotDTO)
          }

          return acc
        }, [])

        if (isDefAndNotEmpty(values)) {
          acc[day] = values
        }
      }

      return acc
    },
    {}
  )
}

function genExercisesTimetableSlotDTO(slot: ScheduleSlot): Nullable<ExercisesTimetableApi.ExercisesTimetableSlotDTO> {
  const { time, room, trainers, maxClientsCount } = slot
  const [timeFrom, timeTo] = time

  if (isDef(timeFrom) && isDef(timeTo) && isDef(room)) {
    return {
      timeFrom,
      timeTo,
      room,
      maxClientsCount: maxClientsCount || 1,
      trainers: trainers || [],
    }
  }

  return null
}

export function mapExercisesTimetableToScheduleTableDataItems(
  exercisesTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable[]>
): ScheduleTableDataItem[] | undefined {
  if (isDefAndNotEmpty(exercisesTimetable)) {
    return exercisesTimetable.reduce<ScheduleTableDataItem[]>((acc, exercisesTimetableItem) => {
      const { id, direction, type, rooms, timeslots, dateFrom, dateTo, trainers, bookingClients, format } =
        exercisesTimetableItem

      if (
        isDef(direction) &&
        isDef(type) &&
        isDef(timeslots) &&
        isDef(dateFrom) &&
        isDef(dateTo)
        // isDefAndNotEmpty(trainers)
      ) {
        const directionEntity = mapDictionaryItemToEntityItem(direction)
        const typeEntity = mapDictionaryItemToEntityItem(type)
        const trainersEntities = mapDictionaryItemsListToEntityItemsList(trainers)
        const days = genDaysFromExercisesTimetableTimeslotsMap(timeslots)
        const timeslotsList = genTimeslotsFromExercisesTimetableTimeslotsMap(timeslots)

        const clients = bookingClients && bookingClients[0]?.phone

        if (
          isDef(directionEntity) &&
          isDef(typeEntity) &&
          isDefAndNotEmpty(days) &&
          isDefAndNotEmpty(timeslotsList) &&
          isDefAndNotEmpty(rooms)
          // isDefAndNotEmpty(trainersEntities)
        ) {
          const scheduleTableDataItem: ScheduleTableDataItem = {
            id,
            direction: directionEntity,
            type: typeEntity,
            trainers: trainersEntities ? trainersEntities : [],
            studioRooms: rooms,
            clients,
            days,
            timeslots: timeslotsList,
            dateFrom,
            dateTo,
            format,
          }

          acc.push(scheduleTableDataItem)
        }
      }

      return acc
    }, [])
  }
}

export function mapExercisesTimetableToScheduleListDataItems(
  exercisesTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable[]>
): ScheduleListDataItem[] | undefined {
  if (isDefAndNotEmpty(exercisesTimetable)) {
    return exercisesTimetable.reduce<ScheduleListDataItem[]>((acc, exercisesTimetableItem) => {
      const { id, direction, rooms, dateFrom, dateTo, trainers, bookingClients, format, totalExercises } =
        exercisesTimetableItem

      if (isDef(direction) && isDef(dateFrom) && isDef(dateTo)) {
        const directionEntity = mapDictionaryItemToEntityItem(direction)
        const trainersEntities = mapDictionaryItemsListToEntityItemsList(trainers)

        const clients = bookingClients && bookingClients[0]?.phone

        if (isDef(directionEntity) && isDefAndNotEmpty(bookingClients) && isDefAndNotEmpty(rooms)) {
          const scheduleTableDataItem: ScheduleListDataItem = {
            id,
            direction: directionEntity,
            trainers: trainersEntities ? trainersEntities : [],
            studioRooms: rooms,
            clients,
            dateFrom,
            dateTo,
            format,
            totalExercises,
            paymentType: bookingClients[0].paymentType,
          }

          acc.push(scheduleTableDataItem)
        }
      }

      return acc
    }, [])
  }
}

function genDaysFromExercisesTimetableTimeslotsMap(
  exercisesTimetableSlotsMap: Nullable<ExercisesTimetableApi.ExercisesTimetableSlotsMap>
): Nullable<Days[]> {
  if (isDef(exercisesTimetableSlotsMap)) {
    return Object.entries(exercisesTimetableSlotsMap).reduce<Days[]>((acc, [key, slots]) => {
      const day = validateStrEnumValue<Days>(Days, key)

      if (isDef(day) && isDefAndNotEmpty(slots)) {
        acc.push(day)
      }

      return acc
    }, [])
  }

  return null
}

function genTimeslotsFromExercisesTimetableTimeslotsMap(
  exercisesTimetableSlotsMap: Nullable<ExercisesTimetableApi.ExercisesTimetableSlotsMap>
): Nullable<ScheduleTableDataItemTimeSlot[]> {
  if (isDef(exercisesTimetableSlotsMap)) {
    const timeslotsSet = Object.entries(exercisesTimetableSlotsMap).reduce<Map<string, ScheduleTableDataItemTimeSlot>>(
      (acc, [key, slots]) => {
        const day = validateStrEnumValue<Days>(Days, key)

        if (isDef(day) && isDefAndNotEmpty(slots)) {
          slots.forEach(slot => {
            const { timeFrom, timeTo } = slot

            if (isDef(timeFrom) && isDef(timeTo)) {
              acc.set(`${timeFrom}-${timeTo}`, {
                timeFrom,
                timeTo,
              })
            }
          })
        }

        return acc
      },
      new Map()
    )

    return Array.from(timeslotsSet.values())
  }

  return null
}

export function genScheduleFormValues(
  exerciseTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable>
): Nullable<ScheduleFormValues> {
  if (isDef(exerciseTimetable)) {
    const { direction, type, dateTo, dateFrom, timeslots, bookingClients, comment, bookClient, forceCancel } =
      exerciseTimetable

    const directionId = direction?.id
    const typeId = type?.id
    if (isDef(directionId) && isDef(typeId) && isDef(dateFrom) && isDef(dateTo)) {
      const slots = genScheduleFormValuesSlotsMap(timeslots)
      if (isDef(slots)) {
        return {
          phone: (bookingClients && bookingClients[0]?.phone) || '',
          paymentType: (bookingClients && bookingClients[0]?.paymentType) || '',
          comment,
          forceCancel: true,
          bookClient,
          bookingClients,
          direction: directionId,
          type: typeId,
          slots,
          date: [dayjs(dateFrom), dayjs(dateTo)],
        }
      }
    }
  }

  return null
}

function genScheduleFormValuesSlotsMap(
  slots: Nullable<ExercisesTimetableApi.ExercisesTimetableSlotsMap>
): Nullable<ScheduleFormSlotsMap> {
  if (isDef(slots)) {
    return Object.keys(slots).reduce<ScheduleFormSlotsMap>((acc, key) => {
      const day = validateStrEnumValue<Days>(Days, key)

      if (isDef(day)) {
        const values = slots[day]?.reduce<ScheduleSlot[]>((acc, slot) => {
          const scheduleFormValuesSlot = genScheduleFormValuesSlot(slot)

          if (isDef(scheduleFormValuesSlot)) {
            acc.push(scheduleFormValuesSlot)
          }

          return acc
        }, [])

        if (isDefAndNotEmpty(values)) {
          acc[day] = values
        }
      }

      return acc
    }, {})
  }

  return null
}

function genScheduleFormValuesSlot(
  slot: Nullable<ExercisesTimetableApi.ExercisesTimetableSlot>
): Nullable<ScheduleSlot> {
  if (isDef(slot)) {
    const { timeFrom, timeTo, trainers, room, maxClientsCount } = slot

    if (isDef(timeFrom) && isDef(timeTo)) {
      return {
        time: [timeFrom, timeTo],
        room,
        maxClientsCount: maxClientsCount,
        trainers: trainers || [],
      }
    }
  }

  return null
}

export function genScheduleOverview(
  timetable: Nullable<ExercisesTimetableApi.ExercisesTimetable>
): Nullable<ScheduleOverview> {
  if (isDef(timetable)) {
    const { direction, dateTo, dateFrom, bookingClients, totalExercises } = timetable

    if (isDefAndNotEmpty(bookingClients)) {
      return {
        phone: bookingClients[0].phone,
        paymentType: bookingClients[0]?.paymentType,
        direction,
        dateFrom,
        dateTo,
        totalExercises,
      }
    }
  }

  return null
}

export function genScheduleExercises(
  exercises: Nullable<ExercisesTimetableApi.ExercisesTimetableExercise[]>
): Nullable<ScheduleExercise[]> {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.map(exercise => {
      const hours = exercise.timeFrom.slice(11, 13)
      const minutes = exercise.timeFrom.slice(14, 16)

      const formattedTime = `${hours}:${minutes}`

      return {
        date: dayjs(exercise.timeFrom).format('DD MMM'),
        timeFrom: formattedTime,
        id: exercise.id,
        duration: formatTimeDifference(exercise.timeFrom, exercise.timeTo),
        studio: exercise.studio,
        room: exercise.room.name,
        trainers: exercise.trainers?.map(trainer => trainer.name),
        paymentType: exercise.masterServiceBooking?.paymentType,
        canceled: exercise.canceled,
      }
    })
  }

  return null
}

export function genScheduleEditExercisesFormValues(
  exercisesFilter: ScheduleExercisesFilter,
  studios?: Nullable<StudiosApi.Studio[]>,
  studioOffset?: number
): ScheduleEditExercisesFormValues {
  const { trainerIds, dayOfWeek, timeFrom, timeTo, roomId } = exercisesFilter

  const formatTime = (time: string) => {
    const date = dayjs.utc(time, 'HH:mm:ss.SSSZ')
    const adjustedDate = date.add(studioOffset || 0, 'hour')

    return adjustedDate.format('HH:mm')
  }

  const values = {
    ...(isDefAndNotEmpty(roomId) && {
      studio: {
        oldValue: studios?.find(studio => studio.rooms?.find(room => room.id === roomId[0]))?.id,
        newValue: undefined,
      },
      room: {
        oldValue: roomId[0],
        newValue: undefined,
      },
    }),
    ...(isDefAndNotEmpty(trainerIds) && {
      trainers: trainerIds?.map(trainer => ({
        oldValue: trainer,
        newValue: undefined,
      })),
    }),
    ...(isDefAndNotEmpty(dayOfWeek) && {
      dayOfWeek: {
        oldValue: dayOfWeek[0],
      },
    }),
    ...(isDef(timeFrom) && {
      timeFrom: {
        oldValue: formatTime(timeFrom),
      },
    }),
    ...(isDef(timeTo) && {
      timeTo: {
        oldValue: formatTime(timeTo),
      },
    }),
  }

  return values
}

export function genScheduleExercisesEditDTO(
  formValues: ScheduleEditExercisesFormValues,
  exercisesFilter: ScheduleExercisesFilter,
  studioOffset?: number
): ExercisesTimetableApi.ExercisesTimetableEditDTO {
  const dto: ExercisesTimetableApi.ExercisesTimetableEditDTO = {
    filter: {},
    update: {},
  }

  const timeZone = studioOffset ?? 0

  // Fill the filter with values from oldValue
  if (formValues.dayOfWeek?.oldValue) {
    dto.filter.dayOfWeek = formValues.dayOfWeek.oldValue
  }

  if (formValues.timeFrom?.oldValue) {
    const time = dayjs(formValues.timeFrom.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    dto.filter.timeFrom = time
  }

  if (formValues.timeTo?.oldValue) {
    const time = dayjs(formValues.timeTo.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    dto.filter.timeTo = time
  }

  if (formValues.room?.oldValue) {
    dto.filter.roomId = formValues.room.oldValue
  }

  if (isDefAndNotEmpty(formValues.trainers)) {
    const noTrainer = formValues.trainers.find(trainer => trainer.oldValue === 'noTrainer')
    dto.filter.trainerIds = !noTrainer
      ? formValues.trainers.map(trainer => trainer.oldValue).filter((value): value is string => value !== undefined)
      : []
  }

  // Fill the filter with values from exercisesFilter
  if (isDefAndNotEmpty(exercisesFilter.timeScope)) {
    dto.filter.timeScope = exercisesFilter.timeScope[0]
  }

  if (isDefAndNotEmpty(exercisesFilter.bookingPaymentStatus)) {
    dto.filter.bookingPaymentStatus = exercisesFilter.bookingPaymentStatus[0]
  }

  if (isDefAndNotEmpty(exercisesFilter.paymentType)) {
    dto.filter.paymentType = exercisesFilter.paymentType[0]
  }

  if (isDefAndNotEmpty(exercisesFilter.bookingStatuses)) {
    dto.filter.bookingStatuses = exercisesFilter.bookingStatuses
  }

  // Extract values from formValues and fill the DTO
  if (
    formValues.dayOfWeek?.newValue &&
    formValues.dayOfWeek?.oldValue &&
    formValues.dayOfWeek?.newValue !== formValues.dayOfWeek?.oldValue
  ) {
    dto.update.dayDelta = {
      dayOfWeek: {
        old: formValues.dayOfWeek.oldValue,
        new: formValues.dayOfWeek.newValue,
      },
    }
  }

  if (
    formValues.timeFrom?.newValue &&
    formValues.timeFrom?.oldValue &&
    formValues.timeFrom.oldValue !== formValues.timeFrom.newValue
  ) {
    const oldTime = dayjs(formValues.timeFrom.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    const newTime = dayjs(formValues.timeFrom.newValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')

    dto.update.timeFrom = {
      old: oldTime,
      new: newTime,
    }
  }

  if (
    formValues.timeTo?.newValue &&
    formValues.timeTo?.oldValue &&
    formValues.timeTo.oldValue !== formValues.timeTo.newValue
  ) {
    const oldTime = dayjs(formValues.timeTo.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    const newTime = dayjs(formValues.timeTo.newValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')

    dto.update.timeTo = {
      old: oldTime,
      new: newTime,
    }
  }

  if (formValues.room?.newValue && formValues.room?.oldValue && formValues.room.oldValue !== formValues.room.newValue) {
    dto.update.roomId = {
      old: formValues.room.oldValue,
      new: formValues.room.newValue,
    }
  }

  if (formValues.trainers) {
    dto.update.trainerIds = {
      old: !formValues.trainers.find(trainer => trainer.oldValue === 'noTrainer')
        ? formValues.trainers.map(trainer => trainer.oldValue).filter((value): value is string => value !== undefined)
        : [],
      new: !formValues.trainers.find(trainer => trainer.newValue === 'noTrainer')
        ? formValues.trainers.map(trainer => trainer.newValue).filter((value): value is string => value !== undefined)
        : [],
    }
  }

  return dto
}

export function genScheduleExercisesAddDTO(
  formValues: ScheduleAddSlotsFormValues
): ExercisesTimetableApi.ExercisesTimetableAddDTO {
  const { slots, date } = formValues

  // Convert dates to strings
  const dateFrom = date[0] ? date[0].format('YYYY-MM-DD') : ''
  const dateTo = date[1] ? date[1].format('YYYY-MM-DD') : ''

  // Create an object for time slots
  const timeslots: ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO = {}

  // Iterate over each day in slots
  for (const day in slots) {
    if (slots.hasOwnProperty(day)) {
      const dayKey = day as Days

      const scheduleSlots = slots[dayKey]

      // Transform each slot into the format ExercisesTimetableSlotDTO
      timeslots[dayKey] = scheduleSlots?.map(slot => {
        const timeFrom = slot.time[0]
        const timeTo = slot.time[1]

        return {
          timeFrom,
          timeTo,
          room: slot.room,
          maxClientsCount: slot.maxClientsCount,
          trainers: slot.trainers,
        }
      })
    }
  }

  return {
    dateFrom,
    dateTo,
    timeslots,
  }
}
