import { FormInstance } from 'antd'
import { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'

import { Days } from '../../../types/days.types'
import { ScheduleFormValues } from '../schedule-form/schedule-form.types'
import { genScheduleSlotsInitialValues } from './schedule-slots.utils'

interface Props {
  form: FormInstance<ScheduleFormValues>
}

export function useScheduleSlots(props: Props) {
  const { form } = props

  const timeRangePlaceholder: [string, string] = ['Начать с', 'Повторять до']

  const [currentDay, setCurrentDay] = useState<Days>(Days.MONDAY)
  const [dateRange, setDateRange] = useState(form.getFieldValue('date') || null)

  const changeCurrentDay = (day: Days) => {
    setCurrentDay(day)

    const dayInSlot = form.getFieldValue(['slots', day])
    if (!dayInSlot) {
      form.setFieldValue(['slots', day], genScheduleSlotsInitialValues())
    }
  }

  const handleDateChange = (dates: Dayjs | unknown) => {
    setDateRange(dates)
  }

  const onCopyHandler = useCallback(
    (day: Days): void => {
      const values = form.getFieldsValue()
      const { slots } = values

      form.setFieldsValue({
        ...values,
        slots: {
          ...slots,
          [currentDay]: slots[day],
        },
      })
    },
    [currentDay, form]
  )

  return {
    timeRangePlaceholder,
    currentDay,
    dateRange,
    changeCurrentDay,
    handleDateChange,
    onCopyHandler,
  }
}
