import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleLongtermPageModalEditRemoveExercisesPayload,
  ScheduleLongtermPageModalEditUpdateExercisesPayload,
} from './schedule-longterm-page-modal-edit.types'

export interface ScheduleLongtermPageModalEditState {
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPageModalEditState = {
  isLoading: false,
  error: null,
}

export const { actions: scheduleLongtermPageModalEditActions, reducer: scheduleLongtermPageModalEditReducer } =
  createSlice({
    name: '@@schedule-longterm-page-modal-edit',
    initialState,
    reducers: {
      updateExercises: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        _action: PayloadAction<ScheduleLongtermPageModalEditUpdateExercisesPayload>
      ) => {
        state.isLoading = true
      },
      updateExercisesSuccess: (state: Draft<ScheduleLongtermPageModalEditState>): void => {
        state.isLoading = false
      },
      updateExercisesError: (state: Draft<ScheduleLongtermPageModalEditState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      removeExercises: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        _action: PayloadAction<ScheduleLongtermPageModalEditRemoveExercisesPayload>
      ) => {
        state.isLoading = true
      },
      removeExercisesSuccess: (state: Draft<ScheduleLongtermPageModalEditState>): void => {
        state.isLoading = false
      },
      removeExercisesError: (state: Draft<ScheduleLongtermPageModalEditState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
