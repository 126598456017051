import axios from 'axios'
import { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'

import { Nullable } from '../../types/lang.types'
import {
  CheckResponse,
  NewClientsResponse,
  Period,
  StudioIncomeResponse,
  TurnoverResponse,
} from './analytics-page.types'

const DEMO_URL = 'https://demo.ai.vivacrm.ru'

const AnalyticsContext = React.createContext<{
  turnover: TurnoverResponse | null
  period: Period
  isLoading: boolean
  setPeriod: (value: Period) => void
  range: [Dayjs, Dayjs] | []
  setRange: (value: [Dayjs, Dayjs]) => void
  check: CheckResponse | null
  studioIncome: StudioIncomeResponse | null
  newClients: NewClientsResponse | null
}>({
  turnover: null,
  period: Period.Day,
  isLoading: false,
  setPeriod: () => {},
  range: [],
  setRange: () => {},
  check: null,
  studioIncome: null,
  newClients: null,
})

export const AnalyTicsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [period, setPeriod] = useState<Period>(Period.Day)
  const [range, setRange] = useState<[Dayjs, Dayjs] | []>([])

  const [turnover, setTurnover] = useState<TurnoverResponse | null>(null)
  const [check, setCheck] = useState<CheckResponse | null>(null)
  const [studioIncome, setStudioIncome] = useState<StudioIncomeResponse | null>(null)
  const [newClients, setNewClients] = useState<NewClientsResponse | null>(null)

  const fetchTurnover = async ({ range }: { range?: [Dayjs, Dayjs] }) => {
    const params = range
      ? {
          firstdate: range[0].format('YYYY-MM-DD'),
          lastdate: range[1].format('YYYY-MM-DD'),
        }
      : {}

    const { data } = await axios<TurnoverResponse>({
      method: 'post',
      url: `${DEMO_URL}/rpm`,
      withCredentials: false,
      data: {
        ...params,
      },
    })

    setTurnover(data)
  }

  const fetchCheck = async ({ range }: { range?: [Dayjs, Dayjs] }) => {
    const params = range
      ? {
          firstdate: range[0].format('YYYY-MM-DD'),
          lastdate: range[1].format('YYYY-MM-DD'),
        }
      : {}

    const { data } = await axios<CheckResponse>({
      method: 'post',
      url: `${DEMO_URL}/check/mini`,
      withCredentials: false,
      data: {
        ...params,
      },
    })

    setCheck(data)
  }

  const fetchStudioIncome = async ({ range }: { range?: [Dayjs, Dayjs] }) => {
    const params = range
      ? {
          firstdate: range[0].format('YYYY-MM-DD'),
          lastdate: range[1].format('YYYY-MM-DD'),
        }
      : {}

    const { data } = await axios<StudioIncomeResponse>({
      method: 'post',
      url: `${DEMO_URL}/studios-income/mini`,
      withCredentials: false,
      data: {
        ...params,
      },
    })

    setStudioIncome(data)
  }

  const fetchNewClients = async ({ range }: { range?: [Dayjs, Dayjs] }) => {
    const params = range
      ? {
          firstdate: range[0].format('YYYY-MM-DD'),
          lastdate: range[1].format('YYYY-MM-DD'),
        }
      : {}

    const { data } = await axios<NewClientsResponse>({
      method: 'post',
      url: `${DEMO_URL}/new-clients/mini`,
      withCredentials: false,
      data: {
        ...params,
      },
    })

    setNewClients(data)
  }

  const fetchData = async ({ range }: { range?: [Dayjs, Dayjs] }) => {
    setIsLoading(true)

    await Promise.all([
      fetchCheck({ range }),
      fetchTurnover({ range }),
      fetchStudioIncome({ range }),
      fetchNewClients({ range }),
    ])

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData({})
  }, [])

  useEffect(() => {
    if (range.length === 2) {
      fetchData({
        range,
      })
    }
  }, [range])

  return (
    <AnalyticsContext.Provider
      value={{
        turnover,
        period,
        setPeriod,
        isLoading,
        range,
        setRange,
        check,
        studioIncome,
        newClients,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => useContext(AnalyticsContext)
