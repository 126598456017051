import { PaymentType } from '@api/types/api.types'
import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'

import { BreaksApi } from '../api/types/breaks.types'
import { ExercisesApi } from '../api/types/exercises-api.types'
import { ExercisesGroupBookFromWaitingFormValues } from '../components/exercises-group/exercises-group-book-from-waiting-form/exercises-group-book-from-waiting-form.types'
import { ExercisesGroupBookingFormValues } from '../components/exercises-group/exercises-group-booking-form/exercises-group-booking-form.types'
import { ExercisesGroupBookingsTableDataItem } from '../components/exercises-group/exercises-group-bookings-table/exercises-group-bookings-table.types'
import { ExercisesGroupBookingComment } from '../components/exercises-group/exercises-group-comment/exercises-group-comment.types'
import {
  ExercisesGroupEditFormValues,
  ExercisesGroupEditFormValuesDTO,
} from '../components/exercises-group/exercises-group-edit-form/exercises-group-edit-form.types'
import { ExercisesFormValuesDTO } from '../components/exercises/exercises-form/exercises-form.types'
import { TimetableDataItem } from '../components/exercises/exercises-table/exercises-table.types'
import { ScheduleModalConflictsFormValues } from '../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.types'
import { genDefaultTimeFormat } from '../format/date.format'
import { formatFormValueToPhoneNumber } from '../format/phone.format'
import { formatPaymentType } from '../format/text.format'
import { ScheduleGroupPageExercise } from '../pages/schedule-group-page/schedule-group-page.types'
import { isDef, isDefAndNotEmpty, NNumber, Nullable } from '../types/lang.types'
import { TimetableItemType } from '../types/timetable.types'
import {
  mapColoredDictionaryItemToEntityItem,
  mapDictionaryItemsListToEntityItemsList,
  mapDictionaryItemToEntityItem,
  mapPaymentTypeToApi,
  mapPaymentTypeToClient,
} from './api.mapping'
import { mapBreaksToTimetableDataItems } from './breaks.mapping'

export function genExercisesCreateDTO(
  studioRoomId: string,
  values: Nullable<ExercisesFormValuesDTO>,
  date: string
): Nullable<ExercisesApi.ExercisesCreateDTO> {
  if (isDef(values)) {
    const { direction, subService, type, time, trainers, maxClientsCount } = values

    if (isDef(time.start) && isDef(time.end)) {
      const dateFrom = date + time.start.substring(date.length)
      const dateTo = date + time.end.substring(date.length)

      if (dayjs(dateFrom).isValid() && dayjs(dateTo).isValid()) {
        return {
          direction: isDef(direction) ? direction : subService,
          type: type,
          timeFrom: dateFrom,
          timeTo: dateTo,
          trainers,
          maxClientsCount,
          room: studioRoomId,
          clientId: values?.clientId,
        }
      }
    }
  }

  return null
}

export function genExercisesGroupEditUpdateDTO(
  values: Nullable<ExercisesGroupEditFormValuesDTO>
): Nullable<ExercisesApi.ExerciseUpdateDTO> {
  if (isDef(values)) {
    const { time, studioId, roomId, direction, trainers, maxClientsCount, comment, type } = values
    const [timeFrom, timeTo] = time

    if (isDef(timeFrom) && isDef(timeTo)) {
      const dateFrom = values.date + timeFrom.substring(values.date.length)
      const dateTo = values.date + timeTo.substring(values.date.length)

      if (dayjs(dateFrom).isValid() && dayjs(dateTo).isValid()) {
        return {
          timeFrom: dateFrom,
          timeTo: dateTo,
          direction,
          studioId,
          roomId,
          maxClientsCount,
          trainers,
          comment,
          type,
        }
      }
    }
  }

  return null
}

export function mapExercisesToTimetableDataItems(
  exercises: Nullable<ExercisesApi.Exercise[]>
): Nullable<TimetableDataItem[]> {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce<TimetableDataItem[]>((acc, exercise) => {
      const { timeFrom, timeTo } = exercise

      const studiosRoom = mapColoredDictionaryItemToEntityItem(exercise.room)

      if (isDef(timeFrom) && isDef(timeTo) && isDef(studiosRoom)) {
        acc.push({
          id: exercise.id,
          timeFrom,
          timeTo,
          direction: mapDictionaryItemToEntityItem(exercise.direction),
          type: exercise.type,
          trainers: mapDictionaryItemsListToEntityItemsList(exercise.trainers),
          studiosRoom,
          clientsCounts: exercise.clientsCount,
          maxClientsCount: exercise.maxClientsCount,
          confirmedCount: exercise.confirmedCount,
          comment: exercise.comment,
          clientComment: exercise.masterServiceBooking?.clientComment,
          isFilled: exercise?.isFilled ?? false,
          isHidden: exercise?.isHidden ?? false,
          paymentType: exercise?.masterServiceBooking?.paymentType,
          masterServiceStatus: exercise?.masterServiceBooking?.status,
          guest: {
            phone: exercise?.masterServiceBooking?.client?.phone,
            fullName: `${exercise?.masterServiceBooking?.client?.firstName ?? ''} ${
              exercise?.masterServiceBooking?.client?.lastName ?? ''
            }`,
            category: exercise?.masterServiceBooking?.client?.category?.name,
          },
          recordType: exercise?.type?.name,
          isMasterService: exercise?.direction?.isMasterService ?? false,
          timetableItemType: TimetableItemType.EXERCISE,
        })
      } else {
        console.error('Invalid exercise timeFrom or timeTo', exercise)
      }

      return acc
    }, [])
  }
  return null
}

export function mapTimetableItemsToTimetableDataItems(
  exercises: Nullable<ExercisesApi.Exercise[]>,
  breaks: Nullable<BreaksApi.Break[]>
): Nullable<TimetableDataItem[]> {
  const exercisesTableDataItems = mapExercisesToTimetableDataItems(exercises)
  const breaksTableDataItems = mapBreaksToTimetableDataItems(breaks)

  return [...(exercisesTableDataItems || []), ...(breaksTableDataItems || [])]
}

export function mapExerciseToScheduleGroupPageExercise(
  exercise: Nullable<ExercisesApi.Exercise>
): Nullable<ScheduleGroupPageExercise> {
  if (isDef(exercise)) {
    const {
      timeFrom,
      timeTo,
      comment,
      commentModifiedAt,
      commentModifiedBy,
      createdAt,
      createdBy,
      maxClientsCount,
      clientsCount,
      confirmedCount,
      organizationId,
      studio,
    } = exercise

    const direction = mapDictionaryItemToEntityItem(exercise.direction)
    const type = exercise.type
    const studiosRoom = mapColoredDictionaryItemToEntityItem(exercise.room)
    const trainers = mapDictionaryItemsListToEntityItemsList(exercise.trainers)

    /**
     * @todo Trainers check should be added after API is implemented
     */
    if (isDef(direction) && isDef(type) && isDef(trainers) && isDef(studiosRoom))
      return {
        id: exercise.id,
        timeFrom,
        timeTo,
        direction,
        type,
        trainers,
        studiosRoom,
        comment,
        commentModifiedAt,
        commentModifiedBy,
        createdAt,
        createdBy,
        maxClientsCount,
        clientsCount,
        confirmedCount,
        organizationId,
        studio,
      }
  }

  return null
}

export function mapExercisesBookingsToExercisesGroupBookingsTableDataItems(
  exerciseBookings: Nullable<ExercisesApi.ExerciseBooking[]>
): ExercisesGroupBookingsTableDataItem[] | undefined {
  if (isDefAndNotEmpty(exerciseBookings)) {
    return exerciseBookings.reduce<ExercisesGroupBookingsTableDataItem[]>((acc, exerciseBooking) => {
      const {
        id,
        client,
        visitConfirmed,
        spot,
        isTrial,
        isCancelled,
        comments,
        clientComment,
        linkedProducts,
        receipts,
      } = exerciseBooking
      const { id: clientId, firstName, lastName, photo, phone, category } = client

      const paymentType = mapPaymentTypeToClient(exerciseBooking.paymentType)

      if (isDef(paymentType)) {
        acc.push({
          id,
          clientId,
          paymentType,
          isCancelled,
          isTrial,
          visitConfirmed,
          placement: spot,
          phone: `+${phone}`,
          photo,
          firstName,
          lastName,
          comments,
          clientComment,
          linkedProducts,
          categoryClient: category,
          receipts,
        })
      }

      return acc
    }, [])
  }
}

export function mapExercisesSpotsToOptions(
  exercisesSpots: Nullable<ExercisesApi.ExerciseSpot[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercisesSpots)) {
    return exercisesSpots.map(
      (exercisesSpot: ExercisesApi.ExerciseSpot): DefaultOptionType => ({
        value: exercisesSpot,
        label: exercisesSpot,
      })
    )
  }
}

export function genExerciseBookingCreateDTO(
  values: ExercisesGroupBookingFormValues
): Nullable<ExercisesApi.ExerciseBookingCreateDTO> {
  const { phone, place } = values
  const paymentType = mapPaymentTypeToApi(
    values.paymentType === 'CREATE_TRANSACTION' ? PaymentType.ON_PLACE : values.paymentType
  )

  if (isDef(paymentType)) {
    return {
      phone: formatFormValueToPhoneNumber(phone).replace(/\D/g, ''),
      paymentType,
      spot: place,
      studioId: values?.studioId,
    }
  }

  return null
}

export function genExerciseBookFromWaitingCreateDTO(
  values: ExercisesGroupBookFromWaitingFormValues,
  phone: string
): Nullable<ExercisesApi.ExerciseBookingCreateDTO> {
  const { place } = values
  const paymentType = mapPaymentTypeToApi(values.paymentType)

  if (isDef(paymentType)) {
    return {
      phone,
      paymentType,
      spot: place,
    }
  }

  return null
}

export function genExerciseEditFormValues(
  exercise: Nullable<ExercisesApi.Exercise>,
  studioOffset: NNumber
): Nullable<ExercisesGroupEditFormValues> {
  if (isDef(exercise) && isDef(studioOffset)) {
    const { direction, type, trainers, timeFrom, timeTo, maxClientsCount, studio, room, comment } = exercise
    const date = dayjs(timeFrom).utcOffset(studioOffset, false)
    if (isDef(direction) && isDef(trainers) && isDef(type)) {
      return {
        direction: direction.id,
        trainers: trainers.map(trainer => trainer.id),
        studioId: studio.id,
        roomId: room.id,
        maxClientsCount: maxClientsCount,
        date: date,
        time: [
          dayjs(timeFrom).utcOffset(studioOffset, false).format(genDefaultTimeFormat()),
          dayjs(timeTo).utcOffset(studioOffset, false).format(genDefaultTimeFormat()),
        ],
        type: type.id,
        comment: comment,
      }
    }
  }

  return null
}

export function genExercisesComments(
  exercisesComments: Nullable<ExercisesApi.ExerciseBookingComment[]>
): ExercisesGroupBookingComment[] | undefined {
  if (isDefAndNotEmpty(exercisesComments)) {
    return exercisesComments.reduce<ExercisesGroupBookingComment[]>((acc, exercisesComment) => {
      const { id, createDate, employee, comment } = exercisesComment
      const { position, firstName, lastName, photo } = employee
      const { name } = position
      const employeeName = `${name} ${firstName} ${lastName}`

      acc.push({
        id,
        createDate,
        comment,
        photo,
        employeeName,
      })

      return acc
    }, [])
  }
}

export function mapExercisesToTrainersOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { trainers } = exercise
      if (isDefAndNotEmpty(trainers)) {
        trainers.forEach(trainer => {
          if (!acc.find(option => option.value === trainer.id)) {
            const { id, name } = trainer
            acc.push({
              value: id,
              label: name,
            })
          }
        })
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapExercisesToDirectionsOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { direction } = exercise
      if (isDef(direction)) {
        if (!acc.find(option => option.value === direction.id)) {
          const { id, name } = direction
          acc.push({
            value: id,
            label: name,
          })
        }
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapExercisesToRecordsOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { type } = exercise
      if (isDef(type)) {
        if (!acc.find(option => option.value === type.id)) {
          const { id, name } = type
          acc.push({
            value: id,
            label: name,
          })
        }
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapExercisesToPaymentTypesOptions(
  exercises: Nullable<ExercisesApi.Exercise[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.reduce((acc: DefaultOptionType[], exercise: ExercisesApi.Exercise) => {
      const { masterServiceBooking } = exercise
      if (isDef(masterServiceBooking)) {
        if (!acc.find(option => option.value === masterServiceBooking.paymentType)) {
          const { paymentType } = masterServiceBooking
          acc.push({
            value: paymentType,
            label: formatPaymentType(paymentType),
          })
        }
      }

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function genExerciseUpdateDTO(
  values: ExercisesApi.Exercise,
  roomId: string,
  timeFrom: string,
  timeTo: string
): ExercisesApi.ExerciseUpdateDTO {
  const { direction, maxClientsCount, trainers, comment, type, studio } = values
  return {
    studioId: studio.id,
    roomId: roomId,
    timeFrom,
    timeTo,
    direction: Number(direction?.id),
    maxClientsCount,
    trainers: isDefAndNotEmpty(trainers) ? trainers.map(trainer => trainer.id) : [],
    comment,
    type: type.id,
  }
}

function getTrainerIds(data: ScheduleModalConflictsFormValues): string[] | undefined {
  if (!data.conflicts || data.conflicts.length === 0) {
    return undefined
  }

  const trainerIds: string[] = []

  data.conflicts.forEach(conflict => {
    if (conflict.noTrainer) {
      return undefined
    } else {
      trainerIds.push(...conflict.trainers)
    }
  })

  return trainerIds.length > 0 ? trainerIds : undefined
}

export function mapConflictsFormValuesToExerciseUpdateDTO(
  forrmValues: ScheduleModalConflictsFormValues,
  studioId: string,
  timeFrom: string,
  timeTo: string,
  directionId?: number
): ExercisesApi.ExerciseUpdateDTO | undefined {
  const getTrainerIds = (): string[] | undefined => {
    if (!forrmValues.conflicts || forrmValues.conflicts.length === 0) {
      return undefined
    }

    const trainerIds: string[] = []

    forrmValues.conflicts.forEach(conflict => {
      if (conflict.noTrainer) {
        return undefined
      } else {
        trainerIds.push(...conflict.trainers)
      }
    })

    return trainerIds.length > 0 ? trainerIds : undefined
  }

  const roomId = forrmValues.conflicts?.find(conflict => conflict.room !== undefined)?.room
  const trainers = getTrainerIds()

  if (roomId && directionId) {
    return {
      direction: directionId,
      studioId,
      timeFrom: timeFrom,
      timeTo: timeTo,
      roomId: roomId,
      trainers: trainers,
    }
  }
}
