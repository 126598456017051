import { put, takeLatest, all, race, take, call, select } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { apiV2 } from '../../../../api/api-v2'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import { genScheduleLongtermPagePeekAboutExercisesFilter } from './schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPagePeekAboutActions } from './schedule-longterm-page-peek-about.slice'

function* fetchPeekData(action: ReturnType<typeof scheduleLongtermPagePeekAboutActions.fetchPeekData>) {
  try {
    const [scheduleResponse, studiosResponse, trainersResponse]: [
      Awaited<ReturnType<typeof api.exercisesTimetableService.fetchById>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>,
      Awaited<ReturnType<typeof api.trainersService.fetchAll>>
    ] = yield all([
      callApi(api.exercisesTimetableService.fetchById, action.payload),
      callApi(api.studiosService.fetchAll, { size: 300 }),
      callApi(api.trainersService.fetchAll, { size: 300 }),
    ])

    yield put(
      scheduleLongtermPagePeekAboutActions.fetchPeekDataSuccess({
        timetable: scheduleResponse.data,
        studios: studiosResponse.data,
        trainers: trainersResponse.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPagePeekAboutActions.fetchPeekDataError(new Error()))
  }
}

function* fetchExercises(action: ReturnType<typeof scheduleLongtermPagePeekAboutActions.fetchExercises>) {
  try {
    const { timetableId, params } = action.payload

    const filter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)

    const response: Awaited<ReturnType<typeof apiV2.exercisesTimetableService.fetchExercisesByTimetableId>> =
      yield callApi(apiV2.exercisesTimetableService.fetchExercisesByTimetableId, timetableId, {
        ...params,
        ...filter,
      })

    yield put(scheduleLongtermPagePeekAboutActions.fetchExercisesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPagePeekAboutActions.fetchExercisesError(new Error()))
  }
}

export function* reFetchExercises(id: string) {
  yield put(
    scheduleLongtermPagePeekAboutActions.fetchExercises({
      timetableId: id,
    })
  )

  yield race([
    take(scheduleLongtermPagePeekAboutActions.fetchExercisesSuccess.type),
    take(scheduleLongtermPagePeekAboutActions.fetchExercisesError.type),
  ])
}

export function* removeExercise(action: ReturnType<typeof scheduleLongtermPagePeekAboutActions.removeExercise>) {
  try {
    const { scheduleId, exerciseId } = action.payload

    yield callApi(api.exercisesService.cancel, exerciseId)
    yield put(scheduleLongtermPagePeekAboutActions.removeExerciseSuccess())
    yield put(modalActions.closeLast())
    yield call(reFetchExercises, scheduleId)
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPagePeekAboutActions.removeExerciseError(new Error()))
  }
}

export function* scheduleLongtermPagePeekAboutSagas() {
  yield takeLatest(scheduleLongtermPagePeekAboutActions.fetchPeekData.type, fetchPeekData)
  yield takeLatest(scheduleLongtermPagePeekAboutActions.fetchExercises.type, fetchExercises)
  yield takeLatest(scheduleLongtermPagePeekAboutActions.removeExercise.type, removeExercise)
}
