import { all, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { KkmAPI } from '../../../../kkm-api/kkm'
import { api } from '../../../../api/api'
import { genTransactionsCreatePagePath, genTransactionsPagePath } from '../../../../format/path.format'
import { modalActions } from '../../../common/modal/modal.slice'
import { transactionsCreatePageModalConfirmActions } from './transactions-create-page-modal-confirm.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { isDef } from '../../../../types/lang.types'
import { AppModal } from '../../../../types/modal.types'

export function* payTransaction(action: ReturnType<typeof transactionsCreatePageModalConfirmActions.payTransaction>) {
  try {
    const { transactionId, studioId, kkmResponses } = action.payload

    yield callApi(api.transactionsService.paid, transactionId, kkmResponses)
    yield put(transactionsCreatePageModalConfirmActions.payTransactionSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_WAITING,
        props: {
          transactionId,
          studioId,
        },
      })
    )
    // yield put(push(genTransactionsCreatePagePath(studioId)))
  } catch (e) {
    console.error(e)
    yield put(transactionsCreatePageModalConfirmActions.payTransactionError(new Error()))
  }
}

export function* transactionsCreatePageModalConfirmSagas() {
  yield takeLatest(transactionsCreatePageModalConfirmActions.payTransaction.type, payTransaction)
}
