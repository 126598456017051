import { useAnalytics } from './analytics-page.hook'
import { ProgressAnalytic } from './progress-analytic/progress-analytic.component'

export const CheckAnalytics = () => {
  const { check, isLoading } = useAnalytics()
  if (!check && !isLoading) {
    return null
  }
  return (
    <ProgressAnalytic
      title="Средний чек"
      value={check?.average || 0}
      isLoading={isLoading}
      max={check?.max || 0}
      last={check?.last || 0}
      yesterday={check?.yesterday || 0}
    />
  )
}
