import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useScheduleLongtermPageParams } from './schedule-longterm-page-hooks/schedule-longterm-page-params.hook'
import { scheduleLongtermPageListActions } from '../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.slice'
import {
  genScheduleLongtermPageListIsLoaded,
  genScheduleLongtermPageListIsLoading,
} from '../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.selectors'
import { peekActions } from '../../store/common/peek/peek.slice'
import { AppPeek } from '../../types/peek.types'

export function useScheduleLongtermPage() {
  const params = useScheduleLongtermPageParams()
  const { scheduleId, studioId } = params

  const dispatch = useDispatch()

  const isLoading = useSelector(genScheduleLongtermPageListIsLoading)
  const isLoaded = useSelector(genScheduleLongtermPageListIsLoaded)

  useEffect(() => {
    if (scheduleId) {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_LONGTERM_PAGE_PEEK_ABOUT,
          props: { scheduleId, studioId },
        })
      )
    }
  }, [dispatch, scheduleId, studioId])

  useEffect(() => {
    dispatch(scheduleLongtermPageListActions.fetchPageData(params))
  }, [dispatch, params])

  useEffect(() => {
    return () => {
      dispatch(scheduleLongtermPageListActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    isLoaded,
  }
}
