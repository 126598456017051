import { all, spawn } from 'redux-saga/effects'

import { scheduleLongtermPageListSagas } from './schedule-longterm-page-list/schedule-longterm-page-list.sagas'
import { scheduleLongtermPageModalAddSagas } from './schedule-longterm-page-modal-add/schedule-longterm-page-modal-add.sagas'
import { scheduleLongtermPageModalConflictsSagas } from './schedule-longterm-page-modal-conflicts/schedule-longterm-page-modal-conflicts.sagas'
import { scheduleLongtermPageModalEditSagas } from './schedule-longterm-page-modal-edit/schedule-longterm-page-modal-edit.sagas'
import { scheduleLongtermPagePeekAboutSagas } from './schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.sagas'

export function* scheduleLongtermPageSagas() {
  yield all(
    [
      scheduleLongtermPageListSagas,
      scheduleLongtermPagePeekAboutSagas,
      scheduleLongtermPageModalEditSagas,
      scheduleLongtermPageModalAddSagas,
      scheduleLongtermPageModalConflictsSagas,
    ].map(saga => spawn(saga))
  )
}
