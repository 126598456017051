import { FilterOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Divider, Flex, TimePicker, Typography } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { FC } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { CustomSelect } from '../../custom-select/custom-select.component'
import { ScheduleExercisesFiltersProps } from './schedule-exercises-filters.types'
import { useScheduleExercisesFilters } from './schedule-exercises-filters.hook'
import { genDaysOptions } from '../../../utils/days.utils'
import './schedule-exercises-filters.styles.less'

dayjs.extend(utc)
dayjs.extend(timezone)

const DEFAULT_DATE = '1970-01-01T00:00'

export const ScheduleExercisesFilters: FC<ScheduleExercisesFiltersProps> = props => {
  const {
    isExercisesLoading,
    studioId,
    exercisesFilter,
    totalExercises,
    dateFrom,
    dateTo,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    onSelectFilter,
    onResetFilter,
    onEditExercises,
    onAddExercises,
  } = props

  const {
    timeFilter,
    isFiltersOpen,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
    disabledDate,
    handleFiltersOpen,
    handleTrainersChange,
    handleStudioChange,
    handleTimeChange,
    handleResetFilter,
  } = useScheduleExercisesFilters({
    dateFrom,
    dateTo,
    exercisesFilter,
    onSelectFilter,
    onResetFilter,
  })
  console.log('exercisesFilter', exercisesFilter)
  return (
    <div className="schedule-exercises-filters">
      <Flex justify="space-between" align="center">
        <Flex gap={16}>
          <Typography.Title level={4} className="schedule-exercises-filters__title">
            Записи
          </Typography.Title>
          <Button
            icon={<FilterOutlined />}
            className="schedule-exercises-filters__button--filters"
            onClick={handleFiltersOpen}
          >
            Фильтры
          </Button>
        </Flex>
        <Flex gap={16}>
          <Button icon={<FilterOutlined />} loading={isExercisesLoading} disabled onClick={onAddExercises}>
            Добавить запись
          </Button>
          <Button
            type="primary"
            loading={isExercisesLoading}
            onClick={onEditExercises}
            disabled={(totalExercises || 0) === 0}
          >
            Действия с {totalExercises} {totalExercises === 1 ? 'записью' : 'записями'}
          </Button>
        </Flex>
      </Flex>
      {isFiltersOpen && (
        <Card className="schedule-exercises-filters__card">
          <Flex wrap="wrap" gap="small">
            {isDefAndNotEmpty(trainersOptions) && (
              <CustomSelect
                options={[{ label: 'Без исполнителя', value: 'noTrainer' }, ...trainersOptions]}
                mode="multiple"
                placeholder="Исполнители"
                value={exercisesFilter.trainerIds}
                disabled={isExercisesLoading}
                onChange={handleTrainersChange}
              />
            )}
            {/* {isDefAndNotEmpty(studiosOptions) && (
              <CustomSelect
                options={studiosOptions}
                value={exercisesFilter.studioId}
                mode="multiple"
                placeholder="Студия"
                maxCount={1}
                onChange={handlStudioChange}
              />
            )} */}
            {isDefAndNotEmpty(roomsOptions) && (
              <CustomSelect
                options={roomsOptions?.filter(room => room.studioId === studioId)}
                value={exercisesFilter.roomId}
                mode="multiple"
                placeholder="Зал"
                maxCount={1}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('roomId', value)}
              />
            )}
            <CustomSelect
              options={genDaysOptions(true)}
              placeholder="День недели"
              mode="multiple"
              maxCount={1}
              value={exercisesFilter.dayOfWeek}
              disabled={isExercisesLoading}
              onChange={value => onSelectFilter('dayOfWeek', value)}
            />
            <TimePicker
              className="schedule-exercises-filters__time-picker"
              style={{ '--custom-select-placeholder': `'Время начала'` } as React.CSSProperties}
              allowClear
              value={timeFilter.timeFrom}
              onChange={date => handleTimeChange(date, 'timeFrom')}
              format="HH:mm"
              placeholder="--:--"
              disabled={isExercisesLoading}
            />
            <TimePicker
              className="schedule-exercises-filters__time-picker"
              style={{ '--custom-select-placeholder': `'Время окончания'` } as React.CSSProperties}
              allowClear
              value={timeFilter.timeTo}
              onChange={date => handleTimeChange(date, 'timeTo')}
              format="HH:mm"
              placeholder="--:--"
              disabled={isExercisesLoading}
            />
          </Flex>
          <Divider style={{ margin: '8px 0' }} />
          <Flex wrap="wrap" gap="small">
            <DatePicker.RangePicker
              className="schedule-exercises-filters__date-picker"
              style={{ '--custom-select-placeholder': `'Даты'` } as React.CSSProperties}
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
              disabled
            />
            <CustomSelect
              options={paymentStatusOptions}
              placeholder="Статус оплаты"
              mode="multiple"
              maxCount={1}
              value={exercisesFilter.bookingPaymentStatus}
              disabled={isExercisesLoading}
              onChange={value => onSelectFilter('bookingPaymentStatus', value)}
            />
            <CustomSelect
              options={paymentTypeOptions}
              placeholder="Метод оплаты"
              mode="multiple"
              maxCount={1}
              value={exercisesFilter.paymentType}
              disabled={isExercisesLoading}
              onChange={value => onSelectFilter('paymentType', value)}
            />
            <CustomSelect
              options={timeScopeOptions}
              placeholder="Статус записи"
              mode="multiple"
              maxCount={1}
              value={exercisesFilter.timeScope}
              disabled={isExercisesLoading}
              onChange={value => onSelectFilter('timeScope', value)}
            />
            <CustomSelect
              options={bookingStatusOptions}
              placeholder="Статус бронирования"
              mode="multiple"
              // maxCount={1}
              value={exercisesFilter.bookingStatuses}
              disabled={isExercisesLoading}
              onChange={value => onSelectFilter('bookingStatuses', value)}
            />
            <Button type="text" className="schedule-exercises-filters__button" onClick={handleResetFilter}>
              Сбросить
            </Button>
          </Flex>
        </Card>
      )}
    </div>
  )
}
