import { all, spawn } from 'redux-saga/effects'

import { customFieldsSettingsSagas } from './common/custom-fields-settings/custom-fields-settings.sagas'
import { studioEntitySagas } from './common/entity/studio/studio.sagas'
import { terminalEntitySagas } from './common/entity/terminal/terminal.sagas'
import { tillEntitySagas } from './common/entity/till/till.sagas'
import { layoutSagas } from './common/layout/layout.sagas'
import routerSagas from './common/router/router.saga'
import { clientsCreatePageSagas } from './pages/clients-create-page/clients-create-page.sagas'
import { clientsEditPageSagas } from './pages/clients-edit-page/clients-edit-page.sagas'
import { clientsPageSagas } from './pages/clients-page/clients-page.sagas'
import { customFieldsPageSaga } from './pages/custom-fields-page/custom-fields-page.sagas'
import { directionsCreatePageSagas } from './pages/directions-create-page/directions-create-page.sagas'
import { directionsEditPageSagas } from './pages/directions-edit-page/directions-edit-page.sagas'
import { directionsPageSagas } from './pages/directions-page/directions-page.sagas'
import { employeesCreatePageSagas } from './pages/employees-create-page/employees-create-page.sagas'
import { employeesEditPageSagas } from './pages/employees-edit-page/employees-edit-page.sagas'
import { employeesPageSagas } from './pages/employees-page/employees-page.sagas'
import { employeesScheduleCreateSagas } from './pages/employees/employees-schedule-create/employees-schedule-create.sagas'
import { employeesScheduleEditSagas } from './pages/employees/employees-schedule-edit/employees-schedule-edit.sagas'
import { employeesSchedulePeekHistorySagas } from './pages/employees/employees-schedule-peek-history/employees-schedule-peek-history.sagas'
import { employeesScheduleSagas } from './pages/employees/employees-schedule/employees-schedule.sagas'
import { exercisesTypesCreatePageSagas } from './pages/exercises-types-create-page/exercises-types-create-page.sagas'
import { exercisesTypesEditPageSagas } from './pages/exercises-types-edit-page/exercises-types-edit-page.sagas'
import { exercisesTypesPageSagas } from './pages/exercises-types-page/exercises-types-page.sagas'
import { franchisesCreatePageSagas } from './pages/franchises-create-page/franchises-create-page.sagas'
import { franchisesEditPageSagas } from './pages/franchises-edit-page/franchises-edit-page.sagas'
import { franchisesPageSagas } from './pages/franchises-page/franchises-page.sagas'
import { goodsPageSagas } from './pages/goods-page/goods-page.sagas'
import { masterServicesCreatePageSagas } from './pages/master-services-create-page/master-services-create-page.sagas'
import { masterServicesEditPageSagas } from './pages/master-services-edit-page/master-services-edit-page.sagas'
import { masterServicesPageSagas } from './pages/master-services-page/master-services-page.sagas'
import { masterServicesWidgetCreatePageSagas } from './pages/master-services-widget-create-page/master-services-widget-create-page.sagas'
import { positionsCreatePageSagas } from './pages/positions-create-page/positions-create-page.sagas'
import { positionsEditPageSagas } from './pages/positions-edit-page/positions-edit-page.sagas'
import { positionsPageSagas } from './pages/positions-page/positions-page.sagas'
import { productsPageSagas } from './pages/products-page/products-page.sagas'
import { productsServicesCreatePageSagas } from './pages/products-services-create-page/products-services-create-page.sagas'
import { productsServicesEditPageSagas } from './pages/products-services-edit-page/products-services-edit-page.sagas'
import { productsSubscriptionsEditPageSagas } from './pages/products-subscriptions-edit-page/products-subscriptions-edit-page.sagas'
import { productsSubscriptionsCreatePageSagas } from './pages/products-subscriptions-сreate-page/products-subscriptions-сreate-page.sagas'
import { salariesPageSagas } from './pages/salaries-page/salaries-page.sagas'
import { scheduleLongtermPageSagas } from './pages/schedule-longterm-page/schedule-longterm-page.sagas'
import { scheduleGroupPageSagas } from './pages/schedule-group-page/schedule-group-page.sagas'
import { scheduleManagementPageSagas } from './pages/schedule-management-page/schedule-management-page.sagas'
import { schedulePageSagas } from './pages/schedule-page/schedule-page.sagas'
import { studiosCreatePageSagas } from './pages/studios-create-page/studios-create-page.sagas'
import { studiosEditPageSagas } from './pages/studios-edit-page/studios-edit-page.sagas'
import { studiosPageSagas } from './pages/studios-page/studios-page.sagas'
import { transactionsCreatePageSagas } from './pages/transactions-create-page/transactions-create-page.sagas'
import { transactionsPageSagas } from './pages/transactions-page/transactions-page.sagas'
import { websocketSagas } from './common/websocket/websocket.sagas'

export function* appSagas() {
  yield all(
    [
      layoutSagas,
      routerSagas,
      websocketSagas,

      studioEntitySagas,

      terminalEntitySagas,

      tillEntitySagas,

      franchisesPageSagas,
      franchisesCreatePageSagas,
      franchisesEditPageSagas,

      directionsPageSagas,
      directionsCreatePageSagas,
      directionsEditPageSagas,

      exercisesTypesPageSagas,
      exercisesTypesCreatePageSagas,
      exercisesTypesEditPageSagas,

      employeesPageSagas,
      employeesScheduleSagas,
      employeesScheduleCreateSagas,
      employeesScheduleEditSagas,
      employeesSchedulePeekHistorySagas,
      employeesCreatePageSagas,
      employeesEditPageSagas,

      salariesPageSagas,

      studiosPageSagas,
      studiosCreatePageSagas,
      studiosEditPageSagas,

      productsPageSagas,
      productsServicesCreatePageSagas,
      productsServicesEditPageSagas,
      productsSubscriptionsCreatePageSagas,
      productsSubscriptionsEditPageSagas,

      schedulePageSagas,
      scheduleManagementPageSagas,
      scheduleLongtermPageSagas,
      scheduleGroupPageSagas,

      clientsPageSagas,
      clientsCreatePageSagas,
      clientsEditPageSagas,

      transactionsPageSagas,
      transactionsCreatePageSagas,

      masterServicesPageSagas,
      masterServicesCreatePageSagas,
      masterServicesEditPageSagas,

      masterServicesWidgetCreatePageSagas,

      positionsPageSagas,
      positionsCreatePageSagas,
      positionsEditPageSagas,

      customFieldsPageSaga,
      customFieldsSettingsSagas,

      goodsPageSagas,
    ].map(saga => spawn(saga))
  )
}
