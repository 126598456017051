import { ExclamationCircleOutlined } from '@ant-design/icons'
import { SelectRecordType } from '@components/ui/select-record-type/ui/select-record-type.component'
import { Button, Col, DatePicker, Flex, Form, Input, InputNumber, Modal, Select, Typography, Row } from 'antd'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { formatDate, formatTime } from '../../../format/date.format'
import { useModal } from '../../../hooks/use-modal.hook'
import { isDef } from '../../../types/lang.types'
import { InputTimeRange } from '../../controls/input-time-range/input-time-range.component'
import { InputTimeRangeValue } from '../../controls/input-time-range/input-time-range.types'
import { useExercisesGroupEditForm } from './exercises-group-edit-form.hook'
import './exercises-group-edit-form.styles.less'
import { ExercisesGroupEditFormProps, ExercisesGroupEditFormValues } from './exercises-group-edit-form.types'
import {
  genExercisesGroupEditFormDisabledDate,
  genExercisesGroupEditFormValuesDTO,
} from './exercises-group-edit-form.utils'

const { confirm } = Modal

export const ExercisesGroupEditForm = (props: ExercisesGroupEditFormProps) => {
  const { schedule, isCompleted, form, loading, studioOffset, onSave, onCancel } = props
  const {
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    directionsOptions,
    exercisesTypesOptions,
    masterServicesOptions,
    subServicesOptions,
    onChangeMasterService,
    onChangeSubServices,
    onChangeStudioRoom,
    onChangeDate,
    onChangeTime,
  } = props
  const {
    timeToValidationRules,
    studiosValidationRules,
    studiosRoomsValidationRules,
    maxClientsCountValidationRules,
    directionValidationRules,
    timeRangePlaceholder,
  } = useExercisesGroupEditForm()

  const studioId = Form.useWatch('studioId', form)

  const onChangeTimeHandler = useCallback(
    (value: InputTimeRangeValue): void => {
      const values = form.getFieldsValue()

      form.setFieldsValue({
        ...values,
        time: value,
      })

      onChangeTime(value)
    },
    [form, onChangeTime]
  )

  const onChangeStudioHandler = useCallback(
    (value: string): void => {
      const values = form.getFieldsValue()

      form.setFieldsValue({
        ...values,
        roomId: undefined,
        studioId: value,
      })
    },
    [form]
  )

  const onFinishHandler = (values: ExercisesGroupEditFormValues): void => {
    if (isDef(studioOffset)) {
      const scheduleFormValuesDTO = genExercisesGroupEditFormValuesDTO(values, studioOffset)
      onSave(scheduleFormValuesDTO)
    }
  }

  const onCancelHandler = () => {
    confirm({
      title: `Отмена записи`,
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены, что хотите отменить запись?`,
      onOk: () => {
        onCancel()
      },
    })
  }

  return (
    <Form
      className="exercises-form"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <>
        {isDef(schedule) && isDef(schedule?.createdAt) && (
          <Flex style={{ marginTop: 15 }}>
            <Typography.Text style={{ color: '#8E8E8E' }}>
              Запись создана {formatDate(schedule.createdAt, 'DD MMMM')} в {formatTime(schedule.createdAt)}
            </Typography.Text>
            {isDef(schedule.createdBy) && (
              <Typography.Text style={{ color: '#8E8E8E' }}>
                , Инициатор:
                <Link to={`/employees/${schedule.createdBy?.id}`}>
                  <span style={{ marginLeft: 5, color: '#096dd9' }}>{schedule?.createdBy?.name}</span>
                </Link>
              </Typography.Text>
            )}
          </Flex>
        )}

        <Col style={{ marginTop: 24 }}>
          <Form.Item name="comment" label={'Комментарий к записи'}>
            <Input.TextArea rows={2} placeholder="Комментарий" disabled={loading} />
          </Form.Item>
          {isDef(schedule) && isDef(schedule?.comment) && isDef(schedule?.commentModifiedAt) && (
            <Typography.Text style={{ marginTop: 2, color: '#8E8E8E' }}>
              Последнее изменение {formatDate(schedule.commentModifiedAt, 'DD MMMM')} в{' '}
              {formatTime(schedule.commentModifiedAt)} {schedule?.commentModifiedBy?.name}
            </Typography.Text>
          )}
        </Col>

        <Row gutter={24} style={{ marginTop: 24 }}>
          <Col span={8}>
            <Form.Item label="Категория записи" name="type" rules={directionValidationRules}>
              <SelectRecordType
                placeholder="Категория"
                showIcon={true}
                value={form.getFieldValue('type')}
                options={exercisesTypesOptions}
                disabled={true}
              />
            </Form.Item>
          </Col>
          {isDef(masterServicesOptions) ? (
            <>
              <Col span={8}>
                <Form.Item label="Персональная услуга" name="masterService" rules={directionValidationRules}>
                  <Select
                    placeholder="Персональная услуга"
                    disabled={loading}
                    loading={loading}
                    options={masterServicesOptions}
                    onChange={onChangeMasterService}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option && typeof option.label === 'string'
                        ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    dropdownClassName="customDropdown"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Услуга"
                  name="subService"
                  rules={[
                    {
                      required: true,
                      message: 'Заполните обязательную услугу',
                    },
                  ]}
                >
                  <Select
                    placeholder="Услуга"
                    disabled={loading}
                    loading={loading}
                    options={subServicesOptions}
                    onChange={onChangeSubServices}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option && typeof option.label === 'string'
                        ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    dropdownClassName="customDropdown"
                  />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={8}>
              <Form.Item label="Групповое направление" name="direction" rules={directionValidationRules}>
                <Select
                  placeholder="Групповое направление"
                  options={directionsOptions}
                  disabled={loading}
                  loading={loading}
                  // onChange={onChangeDirectionHandler}
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownClassName="customDropdown"
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row style={{ marginTop: 24 }} gutter={24}>
          <Col span={8}>
            <Form.Item label="Исполнители" name="trainers">
              <Select
                mode="multiple"
                placeholder="Исполнители"
                disabled={loading}
                loading={loading}
                filterOption={(input, option) => {
                  const label = option?.label ? String(option.label) : ''
                  return label.toLowerCase().includes(input.toLowerCase())
                }}
              >
                {trainersOptions
                  ?.filter(option => !option.filteredOut)
                  ?.map(option => (
                    <Select.Option key={option.value} value={option.value} label={option.label}>
                      <Typography style={{ color: 'black' }}>{option.label}</Typography>
                    </Select.Option>
                  ))}
                {trainersOptions?.some(option => option.filteredOut) && (
                  <Select.OptGroup label="Не работают">
                    {trainersOptions
                      ?.filter(option => option.filteredOut)
                      .map(option => (
                        <Select.Option key={option.value} value={option.value} label={option.label}>
                          <Typography style={{ color: 'grey' }}>{option.label}</Typography>
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Студия" name="studioId" rules={studiosValidationRules}>
              <Select
                placeholder="Студия"
                options={studiosOptions}
                disabled={loading}
                loading={loading}
                onChange={onChangeStudioHandler}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Зал" name="roomId" rules={studiosRoomsValidationRules}>
              <Select
                placeholder="Зал"
                options={studiosRoomsOptions?.filter(room => room.studioId === studioId)}
                disabled={loading}
                loading={loading}
                onChange={onChangeStudioRoom}
              />
            </Form.Item>
          </Col>
        </Row>

        <Flex justify="start" gap={24} style={{ marginTop: 24 }}>
          <Col style={{ width: 250 }}>
            <Form.Item label="Дата" name="date" rules={[{ required: true }]}>
              <DatePicker
                style={{ width: '250px' }}
                placeholder="Дата"
                format="D MMMM"
                allowClear={false}
                disabled={loading}
                onChange={onChangeDate}
              />
            </Form.Item>
          </Col>
          <Col style={{ width: 202 }}>
            <Form.Item label="Время" name="time" rules={[timeToValidationRules]}>
              <InputTimeRange
                value={form.getFieldValue('time')}
                placeholder={timeRangePlaceholder}
                loading={loading}
                onChange={onChangeTimeHandler}
              />
            </Form.Item>
          </Col>
          <Col style={{ width: 100 }}>
            <Form.Item
              label="Вместимость"
              name="maxClientsCount"
              rules={[{ required: true }, ...maxClientsCountValidationRules]}
            >
              <InputNumber
                style={{ width: 100 }}
                placeholder="Вместимость"
                min={1}
                defaultValue={1}
                disabled={loading}
              />
            </Form.Item>
          </Col>
        </Flex>

        <Flex gap={22} style={{ marginTop: 38 }}>
          <Form.Item>
            <Button danger type="text" onClick={onCancelHandler} loading={loading} disabled={loading}>
              Отменить запись
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading} disabled={loading}>
              Сохранить
            </Button>
          </Form.Item>
        </Flex>
      </>
    </Form>
  )
}
