import * as React from 'react'
import { Button, Divider, Flex, Form, Modal } from 'antd'
import { useMemo } from 'react'

import { ScheduleModalAddProps } from './schedule-modal-add.types'
import { ScheduleSlots } from '../schedule-slots/schedule-slots.component'
import { genScheduleFormInitialValues } from '../schedule-form/schedule-form.utils'
import './schedule-modal-add.styles.less'

export const ScheduleModalAdd: React.FC<ScheduleModalAddProps> = props => {
  const { form, isLoading, ...rest } = props
  const { onCancel, onSave } = props

  const [isValid, setIsValid] = React.useState<boolean>(false)
  // const [errorText, setErrorText] = React.useState<null | string>(null)

  // const isCheckSlotsEmpty = (data: any) => {
  //   const { slots } = data

  //   for (const day in slots) {
  //     if (slots.hasOwnProperty(day)) {
  //       const daySlots = slots[day]
  //       if (daySlots) {
  //         for (const slot of daySlots) {
  //           if (slot.time[0] !== null || slot.time[1] !== null || slot.room !== null || slot.totalCapacity !== null) {
  //             return false
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return true
  // }

  // const onSaveHandler = React.useCallback(async () => {
  //   const values = form.getFieldsValue()
  //   const scheduleFormValuesDTO = genScheduleFormValuesDTO(values, studioOffset)

  //   let hasErrors = false
  //   await form.validateFields().catch(err => {
  //     hasErrors = true
  //   })

  //   const iSlotsEmpty = isCheckSlotsEmpty(values)
  //   if (iSlotsEmpty) {
  //     setErrorText('Заполните хотя бы один слот в днях недели')
  //   } else {
  //     setErrorText(null)
  //   }

  //   !hasErrors && onSave(scheduleFormValuesDTO)
  // }, [form, onSave, studioOffset])

  const onSaveHandler = () => {
    const values = form.getFieldsValue()
  }

  const onFieldChangeHandler = React.useCallback(setIsValid, [setIsValid])
  const initialValues = useMemo(() => genScheduleFormInitialValues(), [])
  return (
    <Modal
      className="schedule-modal-add"
      title="Создание персонального длинного расписания"
      onCancel={onCancel}
      visible={true}
      width={704}
      footer={
        <Flex justify="center" gap={16}>
          <Button loading={isLoading} onClick={onCancel}>
            Отменить
          </Button>
          <Button loading={isLoading} type="primary" onClick={onSave}>
            Проверить расписание
          </Button>
        </Flex>
      }
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        disabled={isLoading}
        initialValues={initialValues}
        // onFieldsChange={onFieldsChangeHandler}
        autoComplete="off"
        layout="vertical"
        // onFinish={onSave}
      >
        <Flex vertical gap={16}>
          <Divider className="schedule-modal-add__divider" />
          <ScheduleSlots form={form} isLoading={isLoading} {...rest} />
        </Flex>
      </Form>
    </Modal>
  )
}
