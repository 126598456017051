export enum Period {
  Week = 'week',
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Half = 'half',
  Year = 'year',
}

export type TurnoverDateItem = {
  date: string
  number: number
  sales: number
}

export type TurnoverRefundItem = {
  date: string
  number: number
}

export type TurnoverResponse = {
  datesum: TurnoverDateItem[]
  refund: TurnoverRefundItem[]
  presult: number
  yresult: number
  summary: number
  rsum: number
  average: number
}

export type CheckResponse = {
  average: number
  last: number
  max: number
  period: number
  yesterday: number
}

export type StudioIncomeResponse = {
  average: number
  last: number
  max: number
  period: number
  yesterday: number
}

export type NewClientsResponse = {
  last: number
  max: number
  period: number
  summary: number
  yesterday: number
}
