import { ScheduleModalAdd } from '../../../components/schedule/schedule-modal-add/schedule-modal-add.component'
import { useScheduleLongtermPageModalAdd } from './schedule-longterm-page-modal-add.hook'
import { ScheduleLongtermPageModalAddProps } from './schedule-longterm-page-modal-add.types'

export const ScheduleLongtermPageModalAdd: React.FC<ScheduleLongtermPageModalAddProps> = props => {
  const { scheduleId, studioOffset } = props

  const { form, roomsOptions, trainersOptions, onCancelHandler, onSaveHandler } =
    useScheduleLongtermPageModalAdd(scheduleId)

  return (
    <ScheduleModalAdd
      form={form}
      isLoading={false}
      studioOffset={studioOffset ?? 0}
      studiosRoomsOptions={roomsOptions}
      trainersOptions={trainersOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
    />
  )
}
