import { List } from 'antd'
import { FC, useMemo } from 'react'

import { ScheduleExercisesTableItem } from './schedule-exercises-table-item/schedule-exercises-table-item.component'
import { ScheduleExercisesTableProps } from './schedule-exercises-table.types'
import './schedule-exercises-table.styles.less'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'

export const ScheduleExercisesTable: FC<ScheduleExercisesTableProps> = props => {
  const { isExercisesLoading, exercises, pagination, onChangePage, ...rest } = props

  const paginationConfig = useMemo(
    () => ({
      ...pagination,
      onChange: onChangePage,
    }),
    [onChangePage, pagination]
  )

  if (isExercisesLoading) {
    return <PageLoader />
  }
  if (isDefAndNotEmpty(exercises)) {
    return (
      <List
        itemLayout="vertical"
        dataSource={exercises}
        pagination={paginationConfig}
        renderItem={exercise => <ScheduleExercisesTableItem exercise={exercise} {...rest} />}
      />
    )
  }
  return <PageEmpty description="Занятий не найдено" />
}
