import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { ScheduleLongtermPageModalAddCreateExercisesPayload } from './schedule-longterm-page-modal-add.types'

export interface ScheduleLongtermPageModalAddState {
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPageModalAddState = {
  isLoading: false,
  error: null,
}

export const { actions: scheduleLongtermPageModalAddActions, reducer: scheduleLongtermPageModalAddReducer } =
  createSlice({
    name: '@@schedule-longterm-page-modal-add',
    initialState,
    reducers: {
      addExercises: (
        state: Draft<ScheduleLongtermPageModalAddState>,
        _action: PayloadAction<ScheduleLongtermPageModalAddCreateExercisesPayload>
      ) => {
        state.isLoading = true
      },
      addExercisesSuccess: (state: Draft<ScheduleLongtermPageModalAddState>): void => {
        state.isLoading = false
      },
      addExercisesError: (state: Draft<ScheduleLongtermPageModalAddState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
