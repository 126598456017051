import { Form } from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import { WebsocketTimetableMessage } from '../../../../store/common/websocket/websocket-timetable/websocket-timetable.types'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { ScheduleModalConflictsFormValues } from './schedule-modal-conflicts-exercise.types'

interface Props {
  conflict: WebsocketTimetableMessage
  onEditHandler: (
    exerciseId: string,
    formValues: ScheduleModalConflictsFormValues,
    timeFrom: string,
    timeTo: string
  ) => void
}

export function useScheduleModalConflictsExercise(props: Props) {
  const { conflict, onEditHandler } = props
  const { exercise } = conflict

  const startDate = dayjs(exercise.timeFrom)
  const endDate = dayjs(exercise.timeTo)
  const trainerNames = exercise.trainers.map(trainer => trainer.name).join(', ')

  const [form] = Form.useForm()

  const onFinish = (values: ScheduleModalConflictsFormValues) => {
    const exerciseId = exercise.id
    onEditHandler(exerciseId, values, exercise.timeFrom, exercise.timeTo)
  }

  useEffect(() => {
    if (conflict && isDefAndNotEmpty(conflict.conflicts)) {
      form.setFieldsValue({
        conflicts: conflict.conflicts.map(conflict => ({
          noTrainer: false,
          trainers: [],
          room: conflict.room ? conflict.room.id : undefined,
        })),
      })
    }
  }, [form, conflict])

  return {
    form,
    startDate,
    endDate,
    trainerNames,
    onFinish,
  }
}
