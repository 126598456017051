import { Nullable } from '../../../../types/lang.types'

export enum TransactionStatusAction {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export enum TransactionStatusStatus {
  REFUNDING = 'REFUNDING',
  EXPIRING = 'EXPIRING',
  ONLINE_PAYMENT_CONFIRMATION = 'ONLINE_PAYMENT_CONFIRMATION',
  OFFLINE_PAYMENT_CONFIRMATION = 'OFFLINE_PAYMENT_CONFIRMATION',
  PREPARING_SELL_RECEIPTS = 'PREPARING_SELL_RECEIPTS',
  SAVING_COMMAND_RESPONSES = 'SAVING_COMMAND_RESPONSES',
}

export enum ReceiptsStatus {
  POLLING_RECEIPT = 'POLLING_RECEIPT',
}

export type TransactionStatusItem = {
  action: TransactionStatusAction
  transactionId: string
  errorMessage: Nullable<string>
  status: TransactionStatusStatus
  metadata: {
    receiptIds: string[]
  }
}

export type ReceiptData = {
  action: TransactionStatusAction
  receiptId: string
  status: ReceiptsStatus
  errorMessage?: string
}
