import { Button, Flex, Modal } from 'antd'

import { ScheduleModalEditProps } from './schedule-modal-edit.types'
import './schedule-modal-edit.styles.less'
import { ScheduleEditExercisesForm } from '../schedule-edit-exercises-form/schedule-edit-exercises-form.component'

export const ScheduleModalEdit: React.FC<ScheduleModalEditProps> = props => {
  const { isLoading, totalExercises, onCancel, ...rest } = props

  return (
    <Modal
      className="schedule-modal-edit"
      title="Массовые действия с записями"
      visible={true}
      width={720}
      onCancel={onCancel}
      footer={
        <Flex justify="center">
          <Button loading={isLoading} onClick={onCancel}>
            Отменить
          </Button>
        </Flex>
      }
    >
      <ScheduleEditExercisesForm isLoading={isLoading} totalExercises={totalExercises} {...rest} />
    </Modal>
  )
}
