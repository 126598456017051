import * as React from 'react'

import { TransactionsWaitingModal } from '../../../components/transactions/transactions-waiting-modal/transactions-waiting-modal.component'
import { TransactionsCreatePageModalWaitingProps } from './transactions-create-page-modal-waiting.types'

export const TransactionsCreatePageModalWaiting: React.FC<TransactionsCreatePageModalWaitingProps> = props => {
  const { transactionId } = props

  return <TransactionsWaitingModal transactionId={transactionId} />
}
