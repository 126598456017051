import * as React from 'react'
import { Space, Typography } from 'antd'

import './schedule-slots-header.styles.less'

interface Props {
  className?: string
}

export const ScheduleSlotsHeader: React.FC<Props> = props => {
  return (
    <Space className="schedule-slots-header">
      <Typography.Text className="schedule-slots-header__item" strong>
        Начало и конец
      </Typography.Text>
      <Typography.Text className="schedule-slots-header__item" strong>
        Зал
      </Typography.Text>
      <Typography.Text className="schedule-slots-header__item" strong>
        Вместимость
      </Typography.Text>
      <Typography.Text strong>Исполнители</Typography.Text>
    </Space>
  )
}
