import * as React from 'react'
import { Typography } from 'antd'

export const ScheduleLongtermPageTitle: React.FC = () => {
  return (
    <Typography.Title level={2} style={{ marginTop: 0 }}>
      Длинные записи
    </Typography.Title>
  )
}
